<template>
  <Modal
    ref="modalEnzimatico"
    title="Lote de detergente enzimático"
    no-close-on-esc
    no-close-on-backdrop
    hideXClose
    hide-header
    scrollable
    size="xl"
    @hidden="onHide"
  >
    <div class="main-div">
      <b-overlay
        :show="isBusy || isBusyLocal"
        rounded="sm"
        class="dashboard-overlay"
      >
        <b-row>
          <b-col
            cols="4"
            v-for="(equipamento, index) in ultimosDetergentesEnzimatico"
            v-bind:key="equipamento.id_equipamento"
            class="outbox"
          >
            <div class="input-equipment">
              <b-row>
                <b-col cols="2">
                  <img class="icon" src="@/assets/img/speaker.svg" />
                </b-col>
                <b-col cols="10">
                    <b-row class="row-tittle">
                      <b-col class="informacoes-equipamentos tittle-equipamento">
                        {{ equipamento.equipamento }}
                      </b-col>
                    </b-row>
                    <b-row v-if="equipamento.data">
                      <b-col class="informacoes-equipamentos">
                        {{ equipamento.data_troca }} às {{ equipamento.horario }}h
                      </b-col>
                    </b-row>
                </b-col>
              </b-row>
              <b-row class="row-input">
                <b-col>
                  <b-form-group>
                    <label class="ml-4 tittle-input-lote"
                      >Lote de detergente</label
                    >
                    <b-form-input
                      v-model="loteDetergente[index]"
                      :ref="`ref_${index}`"
                      @input="validateErrors(index)"
                      :state="errors[index]"
                      :id="String(index)"
                      autocomplete="off"
                      class="invision-input ml-4 sm input-lote"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </b-overlay>
    </div>

    <template footer="{}">
      <Button
        text="Confirmar"
        @click="confirmForm"
        :loading="isBusy || isBusyLocal"
        class="float-right"
      />
    </template>

    <Modal
      ref="modalTrocaLote" size="md"
      title="Deseja realmente realizar a troca de detergente dos equipamentos a seguir?"
    >
      <b-container>
        <div class="w">
          <b-row>
            <b-col>
              <div class="box">
                <b-row>
                  <b-col
                    cols="6"
                    v-for="item in lotesModificados"
                    :key="item.equipamento"
                    class="mb-5"
                  >
                    <div class="nome-equipamento"><b>{{ item.equipamento }}</b></div>
                    <div>Lote anterior: {{ item.loteAnterior }}</div>
                    <div>Última troca: {{ item.dataUltimaTroca }}</div>
                    <div>Novo lote: {{ item.lote_detergente }}</div>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-container>
      <template #footer>
        <b-button
          type="button"
          variant="none"
          id="button"
          class="text-white primary-contained-button float-right"
          @click="confirmaTrocaLote()"
          >CONFIRMAR</b-button
        >
      </template>
    </Modal>
  </Modal>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import Modal from '@/components/Utils/Modal';
import Button from '@/components/Utils/Button';
import GenericRequest from '@/services/genericRequest';

export default {
  name: 'LoteDetergenteEnzimatico',
  components: {
    Modal,
    Button,
  },

  props: {
    isBusy: {
      type: Boolean,
    },
    options: {
      type: Object,
      required: false,
    },
  },

  computed: {
    ...mapState(['currentUnidade']),
  },

  data() {
    return {
      errors: [],
      isBusyLocal: false,

      loteDetergente: [],
      lotesModificados: [],

      novosLotes: [],
      ultimosDetergentesEnzimatico: [],
    };
  },

  async mounted() {
    await this.getLastTrocaDetergente();
  },

  methods: {
    onHide() {
      if (this.needsConference) {
        this.$refs.modalEnzimatico.show();
      }
    },

    hide() {
      this.$refs.modalTrocaLote.hide();
      this.$refs.modalEnzimatico.hide();
    },

    async confirmaTrocaLote() {
      await this.confirmaNovosLotes();
      this.hide();
    },

    validateErrors(i) {
      if (i || i === 0) {
        if (!this.loteDetergente[i] || !this.loteDetergente[i].trim()) {
          this.errors = [
            ...this.errors.slice(0, i),
            false,
            ...this.errors.slice(i + 1),
          ];

          return false;
        }

        this.errors = [
          ...this.errors.slice(0, i),
          true,
          ...this.errors.slice(i + 1),
        ];

        return true;
      }

      this.errors = this.loteDetergente.map((lote) => {
        if (!lote || !lote.trim()) {
          return false;
        }

        return true;
      });

      return this.errors.every((e) => e);
    },

    async getLastTrocaDetergente() {
      try {
        this.isBusyLocal = true;

        const result = await GenericRequest.getWithoutPagination(
          {},
          `loteDetergente/checkTrocaDetergente/${this.currentUnidade.id_unidade}`,
        );

        this.needsConference = result.needsConference;

        if (result.needsConference) {
          this.$refs.modalEnzimatico.show();
          this.ultimosDetergentesEnzimatico = result.lastTrocasDetergenteOfUnidade;
        }
      } catch (error) {
        let errorMessage;
        if (
          error.response
          && error.response.data
          && error.response.data.error
        ) {
          errorMessage = error.response.data.error.errorMessage;
        }

        await swal({
          title: 'Oops!',
          text:
            errorMessage || 'Ocorreu um problema ao obter os lotes de detergentes antigos',
          icon: 'error',
          button: 'Continuar',
        });
      } finally {
        this.isBusyLocal = false;
      }
    },

    async confirmaNovosLotes() {
      try {
        this.isBusyLocal = true;

        await GenericRequest.create({ loteTrocas: this.novosLotes }, 'loteDetergente');

        await swal({
          title: 'Bom trabalho!',
          text: 'A atualização dos lotes foi realizada com sucesso!',
          icon: 'success',
          button: 'Continuar',
        });

        this.needsConference = false;
      } catch (error) {
        let errorMessage;
        if (
          error.response
          && error.response.data
          && error.response.data.error
        ) {
          errorMessage = error.response.data.error.errorMessage;
        }

        await swal({
          title: 'Oops!',
          text:
            errorMessage || 'Ocorreu um problema ao tentar atualizar os lotes',
          icon: 'error',
          button: 'Continuar',
        });
      } finally {
        this.isBusyLocal = false;
      }
    },

    confirmForm() {
      if (!this.validateErrors()) return;

      this.lotesModificados = [];
      this.novosLotes = [];

      this.novosLotes = this.ultimosDetergentesEnzimatico.map((lote, index) => {
        const novoLote = {
          equipamento: lote.equipamento,
          id_equipamento: lote.id_equipamento,
          loteAnterior: lote.lote_detergente,
          dataUltimaTroca: lote.data,
          lote_detergente: this.loteDetergente[index],
          data_troca: moment().format(),
          dataTrocaAutomatico: true,
        };

        if (novoLote.loteAnterior !== this.loteDetergente[index]) {
          this.lotesModificados.push(novoLote);
        }

        return novoLote;
      });

      if (this.lotesModificados.length) {
        this.$refs.modalTrocaLote.show();
      } else {
        this.confirmaTrocaLote();
      }
    },
  },
};
</script>

<style>
.row-tittle {
  min-height: 3.5rem;
}
.row-input {
  position: relative;
  bottom: -5px !important;
  left: -10px;
}
.outbox {
  padding: 15px;
  width: 100%;
}
.main-div {
  padding: 25px 25px 25px 25px;
  width: 1100px;
}
.input-equipment {
  width: 100%;
  border: 2px solid #e5e5e5 !important;
  border-radius: 10px;
  margin-bottom: 35px;
  height: 220px;
  padding: 15px 15px 15px 15px;
}
.informacoes-equipamentos {
  font-size: 14px;
  margin-bottom: 1px;
  margin-top: 3px;
  width: 10px;
}
.icon {
  padding: inherit;
}
.tittle-equipamento {
  font-size: 16px;
  font-weight: bold;
}
.input-lote {
  width: 95%;
}

.tittle-input-lote {
  font-weight: bold;
  margin-top: 10px;
}
</style>
