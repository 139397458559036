<template>
  <div class="w-100" style="padding-right: 15px; padding-left: 15px;">
    <b-form-group label="Justificativa*" label-for="justificativa">
      <b-form-input
        name="justificativa"
        :value="justificativa"
        autocomplete="off"
        class="invision-input"
        :disabled="isBusy"
        v-validate="{ required: true, isHigherThanMaxZplChars:[justificativa] }"
        :state="validateState('justificativa')"
        @input="v => $emit('updateModel', { justificativa: v })"
      >
      </b-form-input>
      <b-form-invalid-feedback v-if="justificativa.length > 0">
        A quantidade de caracteres da justificativa superou o limite.
      </b-form-invalid-feedback>
    </b-form-group>
  </div>
</template>
<script>
export default {
  name: 'Justificativa',
  inject: ['validator', 'validateState'],
  created() {
    this.$validator = this.parentValidator;
  },
  data() {
    return {
      justificativa: '',
    };
  },
  props: {
    options: {
      type: Object,
      required: false,
    },
    isBusy: {
      type: Boolean,
    },
  },
};
</script>
