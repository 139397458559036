<template>
  <b-col sm="12" md="12" lg="12" xl="12">
    <YesOrNoRadio
      name="desincrustante"
      label="Presença de desincrustante*"
      v-model="desincrustante"
      @input="(v) => $emit('updateModel', { desincrustante: v })"
      :disabled="isBusy"
      v-validate="{ required: true }"
      :state="validateState('desincrustante')"
    />
  </b-col>
</template>

<script>
import YesOrNoRadio from '@/components/Utils/YesOrNoRadio';

export default {
  name: 'Desincrustante',
  inject: ['validator', 'validateState'],
  created() {
    this.$validator = this.validator;
  },

  components: { YesOrNoRadio },

  data() {
    return {
      // eslint-disable-next-line max-len, no-nested-ternary
      desincrustante: this.options.notSelectedOption ? null : Object.keys(this.options).includes('value') ? this.options.value : true,
    };
  },

  mounted() {
    this.$emit('updateModel', { desincrustante: this.desincrustante });
  },

  props: {
    isBusy: {
      type: Boolean,
    },
    options: {
      /**
      * Chaves usadas para definir a opção do componente YesOrNoRadio,
      * no caso de não estar presentes serão setados os valores padrão
      * @param {boolean} notSelectedOption permite setar ou não uma opção
      * @param {boolean} value define a opção que estará selecionada
      */
      type: Object,
      required: false,
    },
  },
};
</script>
