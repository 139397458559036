<template>
  <div>
    <ExpandableCard :title="titulo">
      <b-table
        class="material-table"
        hover
        responsive
        tbody-class="material-table-body"
        thead-class="material-table-head"
        :tbody-tr-class="getRowClass"
        :items="items"
        :fields="fields"
        @row-hovered="(_, index) => hoveredRow = index"
        @row-unhovered="(_, index) => hoveredRow = null"
      >
        <template #head()="{ field }">
          <!-- Hack para impedir o redimensionamento automático das colunas -->
          <span class="column-header-span" :class="{ invisible: algumMaterialSelecionado }">
            {{ field.label }}
          </span>
        </template>

        <template #head(select)>
          <transition name="appear">
            <b-form-checkbox
              v-show="algumMaterialSelecionado"
              class="header-checkbox"
              inline
              :indeterminate.sync="indeterminate"
              @input="desselecionaTodos()"
            >
              <transition name="horizontal-fade">
                <div v-if="algumMaterialSelecionado" class="icon-list">
                  <button
                    type="button"
                    class="btn btn-sm text--black button-icon"
                    variant="outline-dark"
                    @click="removeTodosMateriais()"
                    v-b-tooltip.hover.top="'Remover todos os materiais selecionados'"
                  >
                    <file-minus-icon class="w20px" />
                  </button>
                </div>
              </transition>
            </b-form-checkbox>
          </transition>
        </template>

        <template #cell(select)="{ item }">
          <b-form-checkbox
            v-if="(item.disponivel || !protocolo) && item.id_material"
            :disabled="!verificaSeMaterialFoiLido(item)"
            v-model="materiaisSelecionados.ids[item.id_material]"
            inline
          />
          <b-form-checkbox
            v-else-if="(item.disponivel || !protocolo)  && item.id_material_generico"
            :disabled="!verificaSeMaterialFoiLido(item)"
            v-model="materiaisSelecionados.genericos[item.id_material_generico]"
            inline
          />
          <check-icon v-else-if="item.finalizado" v-b-tooltip.hover.right="getMensagem(item)" class="w15px"/>
          <alert-triangle-icon v-else v-b-tooltip.hover.right="getMensagem(item)" class="w15px"/>
        </template>

        <template #cell(shownId)="{ item }">
          <span v-if="item.id_material">{{ item.shownId || `T${item.id_material}` }}</span>
          <span v-else>-</span>
        </template>

        <template #cell(acoes)="{ item, index: indexRow }">
          <transition name="horizontal-fade">
            <span
              v-if="protocolo && item.label"
              v-b-tooltip.hover.right="getMensagem(item)"
              class="span-indisponivel"
            >
              {{ item.label }}
            </span>
          </transition>

          <transition name="horizontal-fade">
            <span v-if="verificaSeMaterialFoiLido(item)">
              <b-button
                v-for="(formulario, index) in (getMaterial(item).formularios)"
                :key="`formulario${index}${item.id_material_protocolo}`"
                class="btn btn-sm text--black button-icon mr-2"
                variant="outline-dark"
                @click="editaFormulario(getMaterial(item), formulario.title)"
                v-b-tooltip.hover.top="formulario.title"
              >
                <component
                  :is="getIconComponent(formulario.title)"
                  class="w20px"
                />
              </b-button>

              <b-button
                class="btn btn-sm text--black button-icon"
                variant="outline-dark"
                :class="{ invisible: hoveredRow !== indexRow || !item.disponivel }"
                @click="removeMaterial(getMaterial(item))"
                v-b-tooltip.hover.top="'Remover Material'"
              >
                <file-minus-icon class="w20px" />
              </b-button>
              <b-dropdown
                v-if="getMaterial(item).formularios
                  && getMaterial(item).formularios.length
                  && mostraMenu"
                toggle-class="button"
                class="float-right form-options-button"
                no-caret
                v-b-tooltip.hover.top="'Formulários'"
              >
                <template #button-content>
                 <more-horizontal-icon  class="w20px"/>
                </template>
                <b-dropdown-item
                  v-for="(formulario, index) in (getMaterial(item).formularios)"
                  :key="`formulario${index}${item.id_material_protocolo}`"
                  @click="editaFormulario(getMaterial(item), formulario.title)"
                >
                  {{ formulario.title }}
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </transition>
        </template>
      </b-table>
    </ExpandableCard>
  </div>
</template>

<script>
import ExpandableCard from '@/templates/ExpandableCard';
import TransitionExpand from '@/templates/TransitionExpand';

export default {
  name: 'TableMaterial',

  components: {
    ExpandableCard,
    TransitionExpand,
  },

  props: {
    materiais: {
      type: Array,
      required: true,
    },
    protocolo: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      mostraMenu: false,
      hoveredRow: null,
      materiaisSelecionados: {
        genericos: [],
        ids: [],
      },

      fields: [
        {
          key: 'select',
          label: '',
          sortable: false,
        },
        {
          key: 'descricao',
          label: 'Descrição',
          sortable: false,
        },
        {
          key: 'shownId',
          label: 'ID',
          sortable: false,
        },
        {
          key: 'quantidade_pecas',
          label: 'Peças no cadastro',
          sortable: false,
        },
        {
          key: 'quantidade_atual',
          label: 'Qtd',
          sortable: false,
        },
        {
          key: 'acoes',
          label: '',
          sortable: false,
        },
      ],

      indeterminate: true,
    };
  },

  computed: {
    titulo() {
      if (this.protocolo) {
        return `Protocolo ${this.protocolo.codigo} (${this.protocolo.setor}):`;
      }

      return 'Materiais sem protocolo:';
    },

    materiaisSemProtocolo() {
      return this.materiais.filter((m) => !m.id_material_protocolo
        && (!m.ids_material_protocolo || !m.ids_material_protocolo.filter((el) => el).length));
    },

    materiaisProtocolo() {
      const materiais = this.protocolo.materiais.reduce((acum, material) => {
        if (material.id_material) {
          const adicionado = this.materiais.find((m) => m.id_material_protocolo === material.id_material_protocolo);
          if (adicionado && (!material.disponivel || material.label === 'Substituível')) {
            material.mensagem = `Este material está sendo substituído pelo ID: ${adicionado.shownId}`;
            material.label = 'Substituindo';
          }
          acum.push({ ...material, adicionado: Boolean(adicionado), substituido: true });
          return acum;
        }

        let genericoExistente = acum.find((a) => a.id_material_generico === material.id_material_generico);
        /* && a.finalizado === material.finalizado
          && a.disponivel === material.disponivel
          && a.label === material.label
          && a.mensagem === material.mensagem); */

        if (!genericoExistente) {
          genericoExistente = {
            descricao: material.descricao,
            quantidade_pecas: material.quantidade_pecas,
            id_material_generico: material.id_material_generico,
            finalizado: material.finalizado,
            disponivel: material.disponivel,
            label: material.label,
            mensagem: material.mensagem,
            quantidade_atual: 0,
            quantidade_disponivel: this.protocolo.materiais.filter((id) => id
              .id_material_generico === material.id_material_generico).length,
          };
          acum.push(genericoExistente);
        }
        genericoExistente.quantidade_atual++;

        return acum;
      }, []);

      materiais.filter((m) => m.id_material_generico && m.disponivel)
        .forEach((material) => {
          const generico = this.materiais.find((m) => m.id_material_generico === material.id_material_generico) || {};

          const materiaisProtocolo = (generico.ids_material_protocolo || [])
            .filter((id) => this.protocolo.materiais.map((m) => m.id_material_protocolo).includes(id));
          const quantidadeAdicionada = generico ? materiaisProtocolo.length : 0;
          const quantidadeDisponivel = material.quantidade_atual;

          material.quantidade_atual = `${quantidadeDisponivel - quantidadeAdicionada} / ${quantidadeDisponivel}`;

          if (quantidadeAdicionada) {
            materiais.push({
              ...material,
              label: !material.disponivel ? 'Substituído' : null,
              adicionado: true,
              quantidade_atual: `${quantidadeAdicionada} / ${quantidadeDisponivel}`,
            });
          }
        });

      materiais.filter((m) => m.id_material_generico)
        .forEach((m) => {
          if (m.adicionado && m.disponivel) {
            m.quantidade_atual = `${m.quantidade_atual} LIDOS`;
          } else m.quantidade_atual = `${m.quantidade_atual} NÃO LIDOS`;
        });

      return materiais.filter((m) => !m.invisivel);
    },

    algumMaterialSelecionado() {
      return Boolean(this.materiaisSelecionados.genericos.some((g) => (g)))
        || Boolean(this.materiaisSelecionados.ids.some((i) => i));
    },

    items() {
      const items = this.protocolo ? this.materiaisProtocolo : this.materiaisSemProtocolo;

      return items.sort((a, b) => {
        if (a.adicionado) return -1;
        if (a.disponivel && !b.adicionado) return -1;
        if (a.finalizado) return 1;

        return 0;
      });
    },
  },

  watch: {
    indeterminate() {
      this.indeterminate = true;
    },
  },

  methods: {
    getMensagem(item) {
      if (item.id_material) return item.mensagem;

      return item.mensagem;
    },

    getIconComponent(formTitle) {
      switch (formTitle) {
        case 'Integridade':
          return 'alert-triangle-icon';
        case 'Aviso':
          return 'AddressCard';
        default:
          return 'edit-icon';
      }
    },

    getMaterialFromMaterialProtocolo(materialProtocolo) {
      return this.materiais.find((m) => (m.id_material
        && m.id_material_protocolo === materialProtocolo.id_material_protocolo)
        || (m.id_material_generico
        && m.id_material_generico === materialProtocolo.id_material_generico)) || {};
    },

    verificaSeMaterialFoiLido(item) {
      if (!this.protocolo) return true;

      return item.adicionado;
    },

    getMaterial(item) {
      if (!this.protocolo || !item.disponivel) return item;

      return this.getMaterialFromMaterialProtocolo(item);
    },

    editaFormulario(material, formulario) {
      if (!material.formularios.find((f) => f.title === formulario)) return;

      this.$emit('edicaoFormulario', material, formulario);
    },

    desselecionaTodos() {
      this.materiaisSelecionados = {
        genericos: [],
        ids: [],
      };
    },

    async removeTodosMateriais() {
      const confirmation = await swal({
        title: 'Exclusão de material',
        text: 'Deseja remover todos os materiais selecionados?',
        icon: 'warning',
        buttons: { cancel: 'Não', confirm: 'Sim' },
      });

      if (!confirmation) return;

      Object.keys(this.materiaisSelecionados.genericos).forEach((g) => {
        const material = this.materiais
          .find((m) => m.id_material_generico
            && this.materiaisSelecionados.genericos[g]
            && m.id_material_generico === +g);

        this.removeMaterial(material, true);
      });

      Object.keys(this.materiaisSelecionados.ids).forEach((i) => {
        const material = this.materiais
          .find((m) => m.id_material
            && this.materiaisSelecionados.ids[i]
            && m.id_material === +i);

        this.removeMaterial(material, true);
      });

      this.desselecionaTodos();
    },

    async removeMaterial(material, bypassConfirmation = false) {
      if (!bypassConfirmation) {
        const confirmation = await swal({
          title: 'Exclusão de material',
          text: `Deseja remover o material ${material.descricao}?`,
          icon: 'warning',
          buttons: { cancel: 'Não', confirm: 'Sim' },
        });

        if (!confirmation) return;
      }

      this.$emit('remocaoMaterial', material);
    },

    getRowClass(item) {
      if (!this.protocolo) return 'adicionado';

      if (item.finalizado) return 'finalizado';
      if (item.adicionado) return 'adicionado';
      if (!item.disponivel) return 'indisponivel';
      return 'nao-adicionado';
    },
  },
};
</script>

<style scoped>
.span-indisponivel {
  cursor: help;
  opacity: 1;
  font-size: 13px;
  padding: 6px 9px 6px 9px;
  background: #F0F1FC;
  border-radius: 5px;
}

.button-icon {
  padding: 0;
  background: none;
  transition: 0.4s;
}

.button-icon:hover {
    background-color: #aedac6;
    color: #484b49;
    transition: 0.7s;
  }

.column-header-span {
  transition: 0.4s;
}

.invisible {
  opacity: 0;
}

</style>

<style>
/* TABELA */
.material-table {
  border-radius: 8px;
  border: 1px solid #dee2e6 !important;
}

.material-table .table thead th {
  border: none;
}
.material-table .material-table-body {
  color: #35384D;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 21px;
}

.material-table .material-table-head {
  color: #5E627A;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 21px;
}

.material-table .material-table-head .icon-list {
  position: absolute;
  left: 2rem;
}

.adicionado {
  background: #E6FAF5;
}
.nao-adicionado {
  background: #FFFFFF;
  color: #808394;
}
.indisponivel {
  opacity: 0.6;
}
.finalizado {
  background: #FFFFFF;
}

.form-options-button .button {
  padding: 0;
  background-color: transparent !important;
  color: black !important;
}
</style>
