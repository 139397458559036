<template>
<div class="w-100" style="padding-right: 15px; padding-left: 15px;">
  <b-row>
    <b-col sm="12" md="12" lg="12" xl="12">
      <YesOrNoRadio
        name="perfuro_cortante"
        label="Presença de perfuro cortante"
        v-model="perfuro_cortante"
        :disabled="isBusy"
        v-validate="{ required: true }"
        :state="validateState('perfuro_cortante')"
        @input="(v) => $emit('updateModel', { perfuro_cortante: v })"
      />
    </b-col>
  </b-row>

  <div class="sub-form" v-if="perfuro_cortante">
    <b-row>
      <b-col
        v-if="options.quantidadeItens"
        sm="12"
        md="12"
        lg="12"
        xl="12"
      >
        <b-form-group
          label="Quantidade de perfuro cortantes*"
          label-for="qtd_perfuro_cortantes"
        >
          <b-form-input
            name="qtd_perfuro_cortantes"
            type="number"
            v-model="quantidade_perfuros_cortantes"
            autocomplete="off"
            min="1"
            class="invision-input"
            :disabled="isBusy"
            v-validate="`required|between:1,${options.quantidadeItens}`"
            :state="validateState('qtd_perfuro_cortantes')"
            @input="onQtdPerfurosChange"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row v-if="quantidade_perfuros_cortantes >= 1">
      <b-col sm="12" md="12" lg="12" xl="12">
        <b-form-group
          label="Descrição*"
          label-for="descricao_perfuro_cortante"
        >
          <GenericSelect
            v-for="(_, index) in ids_perfuros_cortantes"
            :key="`idPerfuroCortante${index}`"
            class="invision-select mt-1"
            :name="`descricao_perfuro_cortante-${index}`"
            autocomplete="off"
            maxlength="500"
            labelKey="nome"
            disabledFirstOption
            :value="ids_perfuros_cortantes[index]"
            route="tipoPerfuroCortante"
            :disabled="isBusy"
            v-validate="{ required: true }"
            :state="validateState(`descricao_perfuro_cortante-${index}`)"
            @input="(v) => onChangeIdsPerfuros(v, index)"
          ></GenericSelect>
          <Button
            v-if="quantidade_perfuros_cortantes >= 1 && ids_perfuros_cortantes.length < quantidade_perfuros_cortantes"
            text="Adicionar Descrição"
            variant="secondary"
            class="mt-3"
            @click="addDescricaoPerfuro"
            :disabled="isBusy"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</div>
</template>

<script>
import YesOrNoRadio from '@/components/Utils/YesOrNoRadio';
import Button from '@/components/Utils/Button';
import GenericSelect from '@/components/Form/GenericSelect';

export default {
  name: 'PerfuroCortante',
  inject: ['validator', 'validateState'],
  created() {
    this.$validator = this.validator;
  },

  components: {
    YesOrNoRadio,
    Button,
    GenericSelect,
  },

  data() {
    return {
      perfuro_cortante: this.options.notSelectedOption ? null : Boolean(this.options.value),
      quantidade_perfuros_cortantes: 0,
      ids_perfuros_cortantes: [],

      justificativa: '',
    };
  },

  mounted() {
    this.$emit('updateModel', { perfuro_cortante: this.perfuro_cortante });
  },

  props: {
    isBusy: {
      type: Boolean,
    },
    options: {
      /**
      * Chaves usadas para definir a opção do componente YesOrNoRadio,
      * no caso de não estar presentes serão setados os valores padrão
      * @param {boolean} notSelectedOption permite setar ou não uma opção
      * @param {boolean} value define a opção que estará selecionada
      */
      type: Object,
      required: false,
    },
  },

  methods: {
    onQtdPerfurosChange() {
      if (this.quantidade_perfuros_cortantes < this.ids_perfuros_cortantes.length) {
        this.ids_perfuros_cortantes.length = this.quantidade_perfuros_cortantes;
        this.$emit('updateModel', { ids_perfuros_cortantes: this.ids_perfuros_cortantes });
      }

      this.$emit('updateModel', { quantidade_perfuros_cortantes: this.quantidade_perfuros_cortantes });
    },

    onChangeIdsPerfuros(value, index) {
      this.ids_perfuros_cortantes[index] = value;
      this.$emit('updateModel', { ids_perfuros_cortantes: this.ids_perfuros_cortantes });
    },

    addDescricaoPerfuro() {
      this.ids_perfuros_cortantes.push(null);
    },
  },
};
</script>
