var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-100",staticStyle:{"padding-right":"15px","padding-left":"15px"}},[_c('b-row',[(_vm.options.PhotoView
    && _vm.options.PhotoView.fotosItem
    && _vm.options.PhotoView.fotosItem.length)?_c('PhotoView',{attrs:{"isBusy":_vm.isBusy,"options":_vm.options.PhotoView,"margin":"-8% 0 0 94%"}}):_vm._e(),_c('b-col',{attrs:{"sm":"12","md":"12","lg":"12","xl":"12"}},[_c('b-form-group',{attrs:{"label":((!_vm.options.onlyCheck
          ? 'Leitura'
          : 'Conferência') + " da composição - " + (_vm.itensLidos.length) + " / " + (_vm.options.itens.length)),"label-for":"reader"}},[(!_vm.options.onlyCheck)?_c('b-form-input',{staticClass:"invision-input",attrs:{"name":"reader","autofocus":"","disabled":_vm.isBusy},on:{"input":_vm.onReaderInput},model:{value:(_vm.reader.value),callback:function ($$v) {_vm.$set(_vm.reader, "value", $$v)},expression:"reader.value"}}):_vm._e()],1)],1),(_vm.options.mensagem)?_c('b-col',{staticClass:"box-mensagem red",attrs:{"cols":"12"}},[_c('p',[_c('alert-circle-icon'),_vm._v(" "+_vm._s(_vm.options.mensagem)+" ")],1)]):_vm._e(),(_vm.options.ativaObservacoes)?_c('b-col',{attrs:{"cols":"12"}},[_c('Observacoes',{ref:"form-Observacoes",attrs:{"isBusy":_vm.isBusy},on:{"input":function (v) { return _vm.$emit('updateModel', { observacoes: v }); }}})],1):_vm._e(),(_vm.qtdeLidosMaiorCadastro && _vm.options.limitaQtdLida)?_c('b-col',{staticClass:"box-mensagem red",attrs:{"cols":"12"}},[_c('p',[_c('alert-circle-icon'),_vm._v(" Quantidade de itens lidos maior do cadastrado! ")],1)]):_vm._e(),_c('b-col',{attrs:{"sm":"12","md":"12","lg":"12","xl":"12"}},[_c('b-table',{attrs:{"items":_vm.options.itens,"fields":_vm.fields,"responsive":""},scopedSlots:_vm._u([{key:"cell(quantidadePresente)",fn:function(ref){
          var item = ref.item;
return [_c('b-input-group',[_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.options.validate ? { required: true, min_value: 1, max_value: item.quantidade } : {}),expression:"options.validate ? { required: true, min_value: 1, max_value: item.quantidade } : {}"}],staticClass:"invision-input input-icon",attrs:{"disabled":Boolean(!_vm.itensLidos.find(function (i) { return i.id_item === item.id_item; })),"max":item.quantidade,"min":"1","type":"number","name":("item-" + (item.id_item)),"state":_vm.validateState(("item-" + (item.id_item))),"value":_vm.itensLidos.find(function (i) { return i.id_item === item.id_item; })
              ? _vm.itensLidos.find(function (i) { return i.id_item === item.id_item; }).quantidade : item.quantidadeAtual},on:{"input":function (v) { return _vm.onQuantidade(item.id_item, v); }}})],1)]}},{key:"cell(lido)",fn:function(ref){
              var item = ref.item;
return [(!_vm.options.onlyCheck && item.code)?_c('div',[(_vm.itensLidos.find(function (i) { return i.code === item.code; }))?_c('check-circle-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",value:('Item já identificado'),expression:"'Item já identificado'",modifiers:{"hover":true,"left":true}}],staticClass:"green"}):_c('alert-triangle-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",value:(("Item ainda não identificado (" + (item.code) + ")")),expression:"`Item ainda não identificado (${item.code})`",modifiers:{"hover":true,"left":true}}],staticClass:"yellow"})],1):_c('div',[_c('b-form-checkbox',{staticStyle:{"margin-left":"1rem"},attrs:{"inline":"","checked":Boolean(_vm.itensLidos.find(function (i) { return i.id_item === item.id_item; }))},on:{"input":function (v) { return _vm.onCheck(item.id_item, v); }}})],1)]}},{key:"cell(fotosItem)",fn:function(ref){
              var item = ref.item;
return [(item.PhotoView
            && item.PhotoView.fotosItem
            && item.PhotoView.fotosItem.length)?_c('PhotoView',{attrs:{"isBusy":_vm.isBusy,"options":item.PhotoView,"position":"absolute","margin":"0 0 0 0.7rem"}}):_vm._e()]}}])})],1),_c('b-col',[_c('div',[_c('span',[_c('strong',[_vm._v("Quantidade total lida:")]),_vm._v(" "+_vm._s(_vm.quantidadePecasItensLidos)+" ")])]),_c('br')]),(!_vm.leituraConcluida || !_vm.pecasCompletas)?_c('b-col',{attrs:{"sm":"12","md":"12","lg":"12","xl":"12"}},[_c('b-form-group',{attrs:{"label":"Justificativa*","label-for":"justificativa"}},[_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:({ required: true, isHigherThanMaxZplChars:[_vm.justificativa] }),expression:"{ required: true, isHigherThanMaxZplChars:[justificativa] }"}],staticClass:"invision-input",attrs:{"name":"justificativa","disabled":_vm.isBusy,"state":_vm.validateState('justificativa')},on:{"input":function (v) { return _vm.$emit('updateModel', { justificativa: v }); }},model:{value:(_vm.justificativa),callback:function ($$v) {_vm.justificativa=$$v},expression:"justificativa"}}),(_vm.justificativa && _vm.justificativa.length > 0)?_c('b-form-invalid-feedback',[_vm._v(" A quantidade de caracteres da justificativa superou o limite. ")]):_vm._e()],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }