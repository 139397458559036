<template>
  <b-col sm="12" md="12" lg="12" xl="12">
    <b-form-group
      label="Responsável pela retirada"
      label-for="responsavel_retirada"
      label-class="float-left"
    >
      <info-icon
        v-b-tooltip.hover.right="'Informe o nome do responsável pela retirada dos materiais'"
        class="tooltip-icon"
      />
      <b-form-input
        name="responsavel_retirada"
        v-model="responsavel_retirada"
        @input="(v) => $emit('updateModel', { responsavel_retirada: v })"
        class="invision-input input-icon"
        :disabled="isBusy"
        v-validate="{ required: true, min: 3 }"
        :state="validateState('responsavel_retirada')"
      ></b-form-input>
    </b-form-group>
  </b-col>
</template>

<script>
export default {
  name: 'ResponsavelRetirada',
  inject: ['validator', 'validateState'],
  created() {
    this.$validator = this.validator;
  },
  data() {
    return {
      responsavel_retirada: '',
    };
  },
  props: {
    isBusy: {
      type: Boolean,
    },
    options: {
      type: Object,
      required: false,
    },
  },
};
</script>
