<template>
  <div class="w-100" style="padding-right: 15px; padding-left: 15px;">
    <b-row class="mb-2">
      <b-col>
        <p>Dupla Checagem</p>
      </b-col>
    </b-row>
    <div id="controls-wrapper">
      <b-row>
        <b-col sm="6">
          <b-form-group
            label="Usuario*"
            label-for="usuario_dupla_checagem"
            class="input-text"
          >
            <b-form-input
              id="usuario_dupla_checagem"
              name="usuario_dupla_checagem"
              ref="usuario_dupla_checagem"
              v-model="usuario_dupla_checagem"
              v-validate="{
                required: true,
                ...this.rulesValidationUser
              }"
              :state="validateState('usuario_dupla_checagem')"
              @input="v => $emit('updateModel',{ usuario_dupla_checagem: v })"
              @mouseleave='validateDuplaChecagem()'
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Senha*"
            label-for="senha_dupla_checagem"
            class="input-text"
          >
            <b-form-input
              id="senha_dupla_checagem"
              ref="senha_dupla_checagem"
              name="senha_dupla_checagem"
              type="password"
              v-model="senha_dupla_checagem"
              v-validate="{
                required: true,
                ...this.rulesValidationUser
              }"
              :state="validateState('senha_dupla_checagem')"
              @input="v => $emit('updateModel',{ senha_dupla_checagem: v })"
              @mouseleave='validateDuplaChecagem()'
            />
          </b-form-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import RestResourceUsuario from '@/services/usuario';

export default {
  name: 'DuplaChecagem',
  inject: ['validator', 'validateState'],
  props: {
    isBusy: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    ...mapState(['usuarioLogadoProcesso']),
  },
  created() {
    this.$validator = this.validator;
  },

  data() {
    return {
      usuario_dupla_checagem: '',
      senha_dupla_checagem: '',
      rulesValidationUser: {
        min: '1',
      },
    };
  },

  mounted() {
    this.usuario_dupla_checagem = this.usuarioLogadoProcesso.registro;
    this.$emit('updateModel', {
      usuario_dupla_checagem: this.usuario_dupla_checagem,
      senha_dupla_checagem: this.senha_dupla_checagem,
    });
  },

  methods: {
    async validateDuplaChecagem() {
      if (!this.usuario_dupla_checagem || !this.senha_dupla_checagem) return;
      const duplaChecagemAuth = await RestResourceUsuario.authUserByLoginProps(
        {
          registro: this.usuario_dupla_checagem,
          password: this.senha_dupla_checagem,
          permission: 'rw_dupla_checagem',
        },
        'auth/hasPermission',
      );
      if (!duplaChecagemAuth.length) {
        this.rulesValidationUser.min = '1000000';
        this.rulesValidationUser.regex = new RegExp('^[a@be#%!~^]{1000000}');
        return;
      }
      this.rulesValidationUser.min = '1';
      this.rulesValidationUser.regex = new RegExp('');
    },
  },

};
</script>

<style scoped>
#controls-wrapper {
  border-top: 1px solid #d8d8da;
  background: #f7f7fa;
  padding: 25px;
}
.input-text {
  color: #40434e;
}
</style>
