<template>
  <div class="w-100" style="padding-right: 15px; padding-left: 15px;">
    <b-row>
      <b-col sm="12" md="12" lg="12" xl="12">
       <b-form-group
          label="Equipamento*"
          label-for="id_equipamento_esterilizacao"
        >
          <GenericSelect
            name="id_equipamento_esterilizacao"
            labelKey="nome"
            :customFilters="{ ...options.filters, id_unidade: idUnidade }"
            disabledFirstOption
            route="equipamento"
            :disabled="isBusy"
            :state="validateState('id_equipamento_esterilizacao')"
            v-validate="{ required: true }"
            v-model="id_equipamento_esterilizacao"
            @input="onEquipamentoChange"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12" md="12" lg="12" xl="12">
        <b-form-group
          label="Lote da carga*"
          label-for="lote"
          v-if="equipamentosUtilizamLote.includes(id_equipamento_esterilizacao)"
        >
          <b-form-input
            name="lote"
            autocomplete="off"
            class="invision-input"
            :disabled="isBusy"
            v-validate="{ required: true }"
            :state="validateState('lote')"
            v-model="lote"
            @input="(v) => $emit('updateModel', { lote: v })"
          >
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import GenericSelect from '@/components/Form/GenericSelect';

export default {
  name: 'EquipamentoEsterilizacao',
  inject: ['validator', 'validateState'],
  created() {
    this.$validator = this.validator;
  },

  components: {
    GenericSelect,
  },

  data() {
    return {
      id_equipamento_esterilizacao: null,
      lote: null,
    };
  },

  props: {
    isBusy: {
      type: Boolean,
    },
    options: {
      type: Object,
      required: false,
    },
    idUnidade: {
      type: Number,
      required: true,
    },
  },

  computed: {
    equipamentosUtilizamLote() {
      return this.options.equipamentosComLote || [];
    },
  },

  mounted() {
    this.$emit('updateModel', { id_equipamento_esterilizacao: this.id_equipamento_esterilizacao });
  },

  methods: {
    onEquipamentoChange(value) {
      this.$emit('updateModel', { id_equipamento_esterilizacao: value });

      if (!this.equipamentosUtilizamLote.includes(value)) this.lote = '';
    },
  },
};
</script>
