var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ExpandableCard',{attrs:{"title":_vm.titulo}},[_c('b-table',{staticClass:"material-table",attrs:{"hover":"","responsive":"","tbody-class":"material-table-body","thead-class":"material-table-head","tbody-tr-class":_vm.getRowClass,"items":_vm.items,"fields":_vm.fields},on:{"row-hovered":function (_, index) { return _vm.hoveredRow = index; },"row-unhovered":function (_, index) { return _vm.hoveredRow = null; }},scopedSlots:_vm._u([{key:"head()",fn:function(ref){
var field = ref.field;
return [_c('span',{staticClass:"column-header-span",class:{ invisible: _vm.algumMaterialSelecionado }},[_vm._v(" "+_vm._s(field.label)+" ")])]}},{key:"head(select)",fn:function(){return [_c('transition',{attrs:{"name":"appear"}},[_c('b-form-checkbox',{directives:[{name:"show",rawName:"v-show",value:(_vm.algumMaterialSelecionado),expression:"algumMaterialSelecionado"}],staticClass:"header-checkbox",attrs:{"inline":"","indeterminate":_vm.indeterminate},on:{"update:indeterminate":function($event){_vm.indeterminate=$event},"input":function($event){return _vm.desselecionaTodos()}}},[_c('transition',{attrs:{"name":"horizontal-fade"}},[(_vm.algumMaterialSelecionado)?_c('div',{staticClass:"icon-list"},[_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Remover todos os materiais selecionados'),expression:"'Remover todos os materiais selecionados'",modifiers:{"hover":true,"top":true}}],staticClass:"btn btn-sm text--black button-icon",attrs:{"type":"button","variant":"outline-dark"},on:{"click":function($event){return _vm.removeTodosMateriais()}}},[_c('file-minus-icon',{staticClass:"w20px"})],1)]):_vm._e()])],1)],1)]},proxy:true},{key:"cell(select)",fn:function(ref){
var item = ref.item;
return [((item.disponivel || !_vm.protocolo) && item.id_material)?_c('b-form-checkbox',{attrs:{"disabled":!_vm.verificaSeMaterialFoiLido(item),"inline":""},model:{value:(_vm.materiaisSelecionados.ids[item.id_material]),callback:function ($$v) {_vm.$set(_vm.materiaisSelecionados.ids, item.id_material, $$v)},expression:"materiaisSelecionados.ids[item.id_material]"}}):((item.disponivel || !_vm.protocolo)  && item.id_material_generico)?_c('b-form-checkbox',{attrs:{"disabled":!_vm.verificaSeMaterialFoiLido(item),"inline":""},model:{value:(_vm.materiaisSelecionados.genericos[item.id_material_generico]),callback:function ($$v) {_vm.$set(_vm.materiaisSelecionados.genericos, item.id_material_generico, $$v)},expression:"materiaisSelecionados.genericos[item.id_material_generico]"}}):(item.finalizado)?_c('check-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:(_vm.getMensagem(item)),expression:"getMensagem(item)",modifiers:{"hover":true,"right":true}}],staticClass:"w15px"}):_c('alert-triangle-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:(_vm.getMensagem(item)),expression:"getMensagem(item)",modifiers:{"hover":true,"right":true}}],staticClass:"w15px"})]}},{key:"cell(shownId)",fn:function(ref){
var item = ref.item;
return [(item.id_material)?_c('span',[_vm._v(_vm._s(item.shownId || ("T" + (item.id_material))))]):_c('span',[_vm._v("-")])]}},{key:"cell(acoes)",fn:function(ref){
var item = ref.item;
var indexRow = ref.index;
return [_c('transition',{attrs:{"name":"horizontal-fade"}},[(_vm.protocolo && item.label)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:(_vm.getMensagem(item)),expression:"getMensagem(item)",modifiers:{"hover":true,"right":true}}],staticClass:"span-indisponivel"},[_vm._v(" "+_vm._s(item.label)+" ")]):_vm._e()]),_c('transition',{attrs:{"name":"horizontal-fade"}},[(_vm.verificaSeMaterialFoiLido(item))?_c('span',[_vm._l(((_vm.getMaterial(item).formularios)),function(formulario,index){return _c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(formulario.title),expression:"formulario.title",modifiers:{"hover":true,"top":true}}],key:("formulario" + index + (item.id_material_protocolo)),staticClass:"btn btn-sm text--black button-icon mr-2",attrs:{"variant":"outline-dark"},on:{"click":function($event){_vm.editaFormulario(_vm.getMaterial(item), formulario.title)}}},[_c(_vm.getIconComponent(formulario.title),{tag:"component",staticClass:"w20px"})],1)}),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Remover Material'),expression:"'Remover Material'",modifiers:{"hover":true,"top":true}}],staticClass:"btn btn-sm text--black button-icon",class:{ invisible: _vm.hoveredRow !== indexRow || !item.disponivel },attrs:{"variant":"outline-dark"},on:{"click":function($event){_vm.removeMaterial(_vm.getMaterial(item))}}},[_c('file-minus-icon',{staticClass:"w20px"})],1),(_vm.getMaterial(item).formularios
                && _vm.getMaterial(item).formularios.length
                && _vm.mostraMenu)?_c('b-dropdown',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Formulários'),expression:"'Formulários'",modifiers:{"hover":true,"top":true}}],staticClass:"float-right form-options-button",attrs:{"toggle-class":"button","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('more-horizontal-icon',{staticClass:"w20px"})]},proxy:true}],null,true)},_vm._l(((_vm.getMaterial(item).formularios)),function(formulario,index){return _c('b-dropdown-item',{key:("formulario" + index + (item.id_material_protocolo)),on:{"click":function($event){_vm.editaFormulario(_vm.getMaterial(item), formulario.title)}}},[_vm._v(" "+_vm._s(formulario.title)+" ")])}),1):_vm._e()],2):_vm._e()])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }