<template>
  <b-col sm="12" md="12" lg="12" xl="12">
    <b-form-group
      :label="label"
      label-for="nome_colaborador"
      label-class="float-left"
    >
      <b-form-input
        name="nome_colaborador"
        v-model="nome_colaborador"
        @input="(v) => $emit('updateModel', { nome_colaborador: v })"
        class="invision-input input-icon"
        :disabled="isBusy"
        v-validate="{ required: true }"
        :state="validateState('nome_colaborador')"
      ></b-form-input>
    </b-form-group>
  </b-col>
</template>

<script>
export default {
  name: 'NomeColaborador',
  inject: ['validator', 'validateState'],
  created() {
    this.$validator = this.validator;
  },
  data() {
    return {
      nome_colaborador: '',
    };
  },
  props: {
    isBusy: {
      type: Boolean,
    },
    options: {
      type: Object,
      required: false,
    },
  },
  computed: {
    label() {
      if (this.options && this.options.label) return this.options.label;
      return 'Responsável pelo processamento';
    },
  },
};
</script>
