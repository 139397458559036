<template>
  <b-form-group
    :label="label"
    :label-for="id"
    :class="className"
  >
    <b-form-tags
      :id="id"
      :name="name"
      ref="inputTagsRef"
      input-id="tags-basic"
      :value="value"
      @input="v => $emit('input', v)"
      separator=" ,"
      :autofocus="autofocus"
      :placeholder="placeholder"
      addButtonText="digitando..."
      remove-on-delete
      add-on-change
      :disabled="isBusy && isBusy"
      :state="state"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
    />
    <slot name="spinner" />
    <template #description>
      <slot name="description" />
    </template>
  </b-form-group>
</template>

<script>
export default {
  name: 'InputTags',
  props: {
    isBusy: {
      type: Boolean,
      required: false,
    },
    id: {
      type: String,
      required: false,
    },
    name: {
      type: String,
      default: 'input-tags-default-name',
    },
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
    },
    value: {
      type: Array,
      required: false,
    },
    className: {
      type: String,
      required: false,
    },
    state: {
      type: Boolean,
      required: false,
    },
    autofocus: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    removedTags() {
      return this.$refs.inputTagsRef ? this.$refs.inputTagsRef.removedTags : [];
    },
    tags: {
      get() {
        return this.$refs.inputTagsRef && this.$refs.inputTagsRef.tags;
      },
      set(v) {
        this.$refs.inputTagsRef.tags = v;
      },
    },
  },
};
</script>
