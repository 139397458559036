var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-100",staticStyle:{"padding-right":"15px","padding-left":"15px"}},[(_vm.options.avisoLimiteEsterilizacao)?_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"12","lg":"12","xl":"12"}},[_c('b-alert',{attrs:{"show":"","variant":"warning"}},[_c('b-row',[_c('AlertCircleIcon',{staticClass:"mr-3"}),_c('span',[_vm._v("Material atingiu o limite de esterilizações permitido.")])],1)],1)],1)],1):_vm._e(),(!_vm.options.hideQtdPecas)?_c('b-row',[(_vm.options.quantidadeItens)?_c('b-col',{attrs:{"sm":"12","md":"12","lg":"12","xl":"12"}},[(
          _vm.options.quantidadeAtualItens &&
          _vm.options.quantidadeItens &&
          _vm.options.quantidadeAtualItens !== _vm.options.quantidadeItens
        )?_c('label',[_vm._v(" Esse material foi cadastrado com "+_vm._s(_vm.options.quantidadeItens)+" itens, mas apenas "+_vm._s(_vm.options.quantidadeAtualItens)+" foram informados no último processo. ")]):_vm._e(),_c('b-form-group',{attrs:{"label":"Quantidade de peças*","label-for":"quantidade_pecas"}},[_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.validacao),expression:"validacao"}],staticClass:"invision-input",staticStyle:{"max-width":"15rem"},attrs:{"name":"quantidade_pecas","type":"number","min":"1","disabled":_vm.isBusy,"state":_vm.validateState('quantidade_pecas')},on:{"input":function (v) { return _vm.$emit('updateModel', { quantidade_pecas: v }); }},model:{value:(_vm.quantidade_pecas),callback:function ($$v) {_vm.quantidade_pecas=$$v},expression:"quantidade_pecas"}})],1)],1):_vm._e(),(_vm.modificouQuantidade && !_vm.options.hideJustificativa)?_c('b-col',{attrs:{"sm":"12","md":"12","lg":"12","xl":"12"}},[_c('b-form-group',{attrs:{"label":"Justificativa*","label-for":"justificativa"}},[_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:({
            required: true,
            isHigherThanMaxZplChars: [_vm.justificativa],
          }),expression:"{\n            required: true,\n            isHigherThanMaxZplChars: [justificativa],\n          }"}],staticClass:"invision-input",attrs:{"name":"justificativa","disabled":_vm.isBusy,"state":_vm.validateState('justificativa')},on:{"input":function (v) { return _vm.$emit('updateModel', { justificativa: v }); }},model:{value:(_vm.justificativa),callback:function ($$v) {_vm.justificativa=$$v},expression:"justificativa"}}),(_vm.justificativa.length > 0)?_c('b-form-invalid-feedback',[_vm._v(" A quantidade de caracteres da justificativa superou o limite. ")]):_vm._e()],1)],1):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }