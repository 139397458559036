<template>
  <b-col sm="12" md="12" lg="12" xl="12">
    <b-form-group label="Validade*" label-for="validade">
      <b-form-datepicker
        class="invision-input"
        placeholder="Data de validade"
        name="validade"
        locale="pt"
        :disabled="Boolean(options.validade)"
        v-validate="{ required: true }"
        :state="validateState('validade')"
        v-model="validade"
        @input="(v) => $emit('updateModel', { validade: v })"
      >
      </b-form-datepicker>
    </b-form-group>
  </b-col>
</template>

<script>

export default {
  name: 'Validade',
  inject: ['validator', 'validateState'],
  created() {
    this.$validator = this.validator;
  },

  data() {
    return {
      validade: this.options.validade,
    };
  },

  mounted() {
    this.$emit('updateModel', { validade: this.options.validade });
  },

  props: {
    isBusy: {
      type: Boolean,
    },
    options: {
      type: Object,
      required: false,
    },
  },
};
</script>
