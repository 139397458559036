<template>
  <div class="w-100" style="padding-right: 15px; padding-left: 15px;">
    <b-row>
      <PhotoView :isBusy="isBusy" :options="options.PhotoView" margin="-8% 0 0 94%" v-if="options.PhotoView
      && options.PhotoView.fotosItem
      && options.PhotoView.fotosItem.length" />
      <b-col sm="12" md="12" lg="12" xl="12">
        <b-form-group
          :label="`${!options.onlyCheck
            ? 'Leitura'
            : 'Conferência'} da composição - ${itensLidos.length} / ${options.itens.length}`"
          label-for="reader">
          <b-form-input v-if="!options.onlyCheck" class="invision-input" name="reader" autofocus v-model="reader.value"
            :disabled="isBusy" @input="onReaderInput"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols="12" v-if="options.mensagem" class="box-mensagem red">
        <p>
          <alert-circle-icon/>
          {{ options.mensagem }}
        </p>
      </b-col>
      <b-col cols="12" v-if="options.ativaObservacoes">
        <Observacoes
          ref="form-Observacoes"
          :isBusy="isBusy"
          @input="(v) => $emit('updateModel', { observacoes: v })"
        />
      </b-col>

      <b-col cols="12" v-if="qtdeLidosMaiorCadastro && options.limitaQtdLida" class="box-mensagem red">
        <p>
          <alert-circle-icon/>
          Quantidade de itens lidos maior do cadastrado!
        </p>
      </b-col>

      <b-col sm="12" md="12" lg="12" xl="12">
        <b-table :items="options.itens" :fields="fields" responsive>
          <template #cell(quantidadePresente)="{ item }">
            <b-input-group>
              <b-form-input
                :disabled="Boolean(!itensLidos.find((i) => i.id_item === item.id_item))"
                class="invision-input input-icon"
                :max="item.quantidade"
                min=1
                type="number"
                :name="`item-${item.id_item}`"
                v-validate="options.validate ? { required: true, min_value: 1, max_value: item.quantidade } : {}"
                :state="validateState(`item-${item.id_item}`)"
                :value="itensLidos.find((i) => i.id_item === item.id_item)
                ? itensLidos.find((i) => i.id_item === item.id_item).quantidade : item.quantidadeAtual"
                @input="(v) => onQuantidade(item.id_item, v)"
              ></b-form-input>
            </b-input-group>
          </template>

          <template #cell(lido)="{ item }">
            <div v-if="!options.onlyCheck && item.code">
              <check-circle-icon
                v-if="itensLidos.find((i) => i.code === item.code)"
                class="green"
                v-b-tooltip.hover.left="'Item já identificado'"
              />
              <alert-triangle-icon
                v-else
                class="yellow"
                v-b-tooltip.hover.left="`Item ainda não identificado (${item.code})`"
              />
            </div>
            <div v-else>
              <b-form-checkbox inline @input="(v) => onCheck(item.id_item, v)"
                style="margin-left: 1rem"
                :checked="Boolean(itensLidos.find((i) => i.id_item === item.id_item))"
              />
            </div>
          </template>
          <template #cell(fotosItem)="{ item }">
            <PhotoView
              :isBusy="isBusy"
              :options="item.PhotoView"
              position="absolute"
              margin="0 0 0 0.7rem"
              v-if="item.PhotoView
              && item.PhotoView.fotosItem
              && item.PhotoView.fotosItem.length"
            />
          </template>
        </b-table>
      </b-col>

      <b-col>
        <div>
          <span>
            <strong>Quantidade total lida:</strong> {{ quantidadePecasItensLidos }}
          </span>
        </div>

        <br />
      </b-col>

      <b-col sm="12" md="12" lg="12" xl="12" v-if="!leituraConcluida || !pecasCompletas">
        <b-form-group label="Justificativa*" label-for="justificativa">
          <b-form-input class="invision-input" name="justificativa" v-model="justificativa"
            @input="(v) => $emit('updateModel', { justificativa: v })" :disabled="isBusy"
            v-validate="{ required: true, isHigherThanMaxZplChars:[justificativa] }"
            :state="validateState('justificativa')">
          </b-form-input>
          <b-form-invalid-feedback v-if="justificativa && justificativa.length > 0">
            A quantidade de caracteres da justificativa
            superou o limite.
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import GenericApi from '@/services/genericRequest';
import PhotoView from '@/components/Utils/PhotoView';
import Observacoes from './Observacoes';

export default {
  name: 'QuantidadePecas',
  inject: ['validator', 'validateState'],
  created() {
    this.$validator = this.validator;
  },
  components: {
    PhotoView,
  },
  data() {
    return {
      itensLidos: [],
      Observacoes,
      justificativa: '',
      fotosItem: [],
      descricaoItem: '',

      reader: {
        timeout: null,
        value: '',
      },

      fields: [
        {
          key: 'descricao',
          label: 'Descrição',
          sortable: false,
        },
        {
          key: 'quantidade',
          label: 'Composto por X peças',
          sortable: false,
        },
        {
          key: 'quantidadePresente',
          label: 'Quantidade Presente',
          sortable: false,
        },
        {
          key: 'fotosItem',
          label: 'Fotos',
          sortable: false,
        },
        {
          key: 'lido',
          label: 'Check',
          sortable: false,
        },
      ],
    };
  },

  props: {
    isBusy: {
      type: Boolean,
    },
    options: {
      type: Object,
      required: false,
    },
  },

  computed: {
    leituraConcluida() {
      return this.itensLidos.length === this.options.itens.length;
    },

    pecasCompletas() {
      return this.itensLidos.every((itemLido) => {
        const itemExistente = this.options.itens.find((i) => i.id_item === itemLido.id_item);

        return itemExistente.quantidade === +itemLido.quantidade;
      });
    },

    quantidadePecasItensLidos() {
      return this.itensLidos.reduce((soma, item) => {
        const result = soma + +item.quantidade;
        return result;
      }, 0);
    },

    qtdeLidosMaiorCadastro() {
      const itensCadastro = this.options.itens.reduce((soma, item) => {
        const result = soma + +item.quantidade;
        return result;
      }, 0);

      return this.quantidadePecasItensLidos > itensCadastro;
    },
  },

  watch: {
    itensLidos: {
      handler() {
        if (this.itensLidos && this.itensLidos.length <= 0 && this.justificativa) {
          swal({
            title: 'Checagem de peças pendente',
            icon: 'error',
            text: 'Por favor faça a checagem de peças para poder continuar.',
            button: { confirm: 'Ok...' },
          });
        }
      },
    },
  },

  mounted() {
    if (this.options.checkedCircleIcon) {
      this.options.itens.forEach((i) => this.itensLidos.push(
        {
          code: i.code, id_item: i.id_item, quantidade: i.quantidadeAtual,
        },
      ));
    }
    this.$emit('updateModel', { itensLidos: this.itensLidos || [] });
  },

  methods: {
    async onDetection(value) {
      if (this.itensLidos.find((i) => i.code === value)) {
        return;
      }
      if (!this.options.itens.map((i) => i.code).includes(value)) {
        const caixaOfItem = await GenericApi.get({}, `item/composicao/${value}`);
        let text = 'O item lido não pertence ao material informado.';
        if (caixaOfItem && caixaOfItem.length) {
          text = text.concat(` Ele pertence ao material de ID ${caixaOfItem[0]
            .material.id} - ${caixaOfItem[0].material.descricao}`);
        }
        swal({
          title: 'Item não presente',
          text,
          icon: 'warning',
        });

        return;
      }

      const item = this.options.itens.find((i) => i.code === value);

      this.itensLidos.push({ code: value, id_item: item.id_item, quantidade: item.quantidadeAtual });
      this.$emit('updateModel', { itensLidos: this.itensLidos });

      if (this.leituraConcluida && this.pecasCompletas) {
        this.$emit('updateModel', { justificativa: null });
        await this.$nextTick();
        this.$emit('confirmaForm');
      }
    },

    onReaderInput(value) {
      if (this.isBusy || !value) return;

      if (this.reader.timeout) clearTimeout(this.reader.timeout);
      this.reader.timeout = setTimeout(async () => {
        await this.onDetection(value);
        this.reader.value = '';
      }, 500);
    },

    onCheck(id_item, value) {
      if (value) {
        if (this.itensLidos.find((i) => i.id_item === id_item)) return;

        const item = this.options.itens.find((i) => i.id_item === id_item);
        this.itensLidos.push({ id_item, quantidade: item.quantidadeAtual });
      } else {
        const index = this.itensLidos.findIndex((i) => i.id_item === id_item);
        if (index !== -1) this.itensLidos.splice(index, 1);
      }

      this.$emit('updateModel', { itensLidos: this.itensLidos });

      if (this.leituraConcluida && this.pecasCompletas) {
        this.$emit('updateModel', { justificativa: null });
      }
    },

    onQuantidade(id_item, quantidade) {
      let qtdeItensLidos = +quantidade;

      if (this.options.limitaQtdLida) {
        // Validar qtde itens lidos nao ser maior do que cadastrado
        const qtdeItemCadastro = (this.options.itens.find((i) => i.id_item === id_item)).quantidade;
        if (qtdeItensLidos > qtdeItemCadastro) {
          qtdeItensLidos = qtdeItemCadastro;
        }
      }

      const item = this.itensLidos.find((i) => i.id_item === id_item);
      if (item) item.quantidade = qtdeItensLidos;
    },
  },
};
</script>

<style scoped>
.green {
  color: #21cca9;
  margin-left: 0.7rem;
}

.yellow {
  color: #e6bc27;
  margin-left: 0.7rem;
}

.red {
  color: #E52E41
}

.box-mensagem {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  align-items: center;
}
</style>
