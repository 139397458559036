<template>
  <b-col sm="12" md="12" lg="12" xl="12">
    <label for="hospital_parceiro">Hospital Parceiro</label>
    <GenericSelect
      name="hospital_parceiro"
      labelKey="nome"
      route="hospitalParceiro"
      v-model="id_hospital_parceiro"
      disabledFirstOption
      @input="v => $emit('updateModel', { id_hospital_parceiro: v })"
      v-validate="{ required: true }"
      :state="validateState('hospital_parceiro')"
    />
  </b-col>
</template>

<script>
import GenericSelect from '@/components/Form/GenericSelect';

export default {
  name: 'HospitalParceiro',
  inject: ['validator', 'validateState'],
  created() {
    this.$validator = this.validator;
  },

  components: {
    GenericSelect,
  },

  data() {
    return {
      id_hospital_parceiro: null,
    };
  },

  props: {
    isBusy: {
      type: Boolean,
    },
    options: {
      type: Object,
      required: false,
    },
  },
};
</script>
