<template>
  <div id="section">
    <b-overlay
      :show="isLoading"
      rounded="sm"
      class="dashboard-overlay"
    >
      <div id="content" class="header">
        <b-row>
          <b-col cols="12">
            <label class="subsetor-label">
              {{ subSetor ? subSetor.nome : '' }}
              <b-dropdown
                variant="outline-primary"
                class="subsetor-dropdown"
                toggle-class="button"
                no-caret
              >
              <template #button-content>
                <chevron-down-icon class="chevron" />
              </template>
                <b-dropdown-item
                  v-for="subsetor in subSetoresFiltered"
                  :key="subsetor.chave"
                  :disabled="(subsetor.chave === subSetor.chave)"
                  @click="changeSubsetor(subsetor)"
                > {{ subsetor.nome }}
                </b-dropdown-item>
              </b-dropdown>
            </label>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="6">
            <MaterialDetection
              name="qrCode"
              :isBusy="isLoading"
              :hideCamera="hideCamera"
              @input="onMaterialSelection"
              :idTipoProcesso="tipoProcessoSelecionado.id_tipo_processo"
              :hideMateriaisGenerico="hideMateriaisGenerico"
            />
          </b-col>
          <b-col class="text-right" cols="6">
            <Button
              class="mr-2"
              text="Cancelar"
              variant="grayscale"
              type="text"
              @click="cancel"
            />
            <Button
              text="Finalizar"
              :disabled="!materiaisLidos.length"
              @click="save"
            />
          </b-col>
        </b-row>
      </div>

      <div :class="{ hidden: materiaisLidos.length || protocolos.length }" class="empty-div text-center mt-4 pt-4">
        <label class="empty-label">Você ainda não realizou nenhuma leitura.</label>
      </div>

      <transition-expand>
        <div v-if="materiaisLidos.length || protocolos.length" class="main">
          <b-row>
            <b-col cols="8">
              <div
                v-if="materiaisLidos.some((m) => !m.id_material_protocolo
                && (!m.ids_material_protocolo || !m.ids_material_protocolo.filter((el) => el).length))"
              >
                <TableMaterial
                    :materiais="materiaisLidos"
                    @edicaoFormulario="abreEdicaoFormularioMaterial"
                    @remocaoMaterial="removeMaterial"
                  />
                <br />
                <br />
              </div>
              <div v-for="(protocolo, index) in protocolos" :key="`protocolo${index}`">
                <TableMaterial
                  :materiais="materiaisLidos"
                  :protocolo="protocolo"
                  @edicaoFormulario="abreEdicaoFormularioMaterial"
                  @remocaoMaterial="removeMaterialProtocolo"
                />
                <br />
                <br />
              </div>
            </b-col>
            <b-col cols="4">
              <b-row>
                <b-col cols="12 mb-4">
                  <ExpandableCard
                    :title="tipoProcessoSelecionado.nome"
                    subTitle="Processo que está sendo realizado"
                  />
                </b-col>
                <b-col cols="12">
                  <ExpandableCard
                    title="Dados do Processamento"
                    subTitle="Informe os dados do processamento"
                  >
                    <form
                      @keyup.enter="save()"
                      @submit.stop.prevent
                      data-vv-scope="main-form"
                    >
                      <DynamicForm
                        v-if="processoFormulario.pages && processoFormulario.pages.length"
                        class="pb-4"
                        :isBusy="isLoading"
                        :fields="processoFormulario.pages[0].fields"
                        :answers="respostasProcessoFormulario"
                        :idUnidade="this.currentUnidade && +this.currentUnidade.id_unidade"
                        @updateModel="onUpdatedProcessoFormAnswer"
                      />
                      <label class="empty-form" v-else>
                        Esse processo não possui formulário
                      </label>
                    </form>
                  </ExpandableCard>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
      </transition-expand>
    </b-overlay>

    <Modal
      ref="modalFormularioMaterial"
      :title="`${formularioMaterialSelecionado.title} - Material ${materialSelecionado.descricao}`"
      no-close-on-esc
      no-close-on-backdrop
      size="lg"
      centered
      @hidden="cancelaFormularioMaterial"
    >
      <b-col sm="12" md="12" lg="12" xl="12" v-if="countPhotos">
        <label
          v-if="
            countPhotos >= 30
          "
        >
          Esse processo atingiu o limite de {{ countPhotos }} adicionadas,
          não sendo possível adicionar mais fotos à seus materiais.
        </label>
      </b-col>
      <form @keyup.enter="confirmaFormularioMaterial()" @submit.stop.prevent data-vv-scope="formulario-material">
        <DynamicForm
          :isBusy="isLoading"
          :fields="formularioMaterialSelecionado.fields"
          :answers="respostasMaterialFormulario"
          :idUnidade="this.currentUnidade && +this.currentUnidade.id_unidade"
          :countPhotos="countPhotos"
          @updateModel="onUpdatedMaterialFormAnswer"
          @confirma="confirmaFormularioMaterial"
          @cancela="cancelaFormularioMaterial"
        />
      </form>

      <template #footer>
        <b-row align-h="between" class="pt-4">
          <b-col>
            <Button
              text="Cancelar"
              variant="grayscale"
              type="text"
              v-if="promiseFormularioMaterialObrigatorio"
              @click="cancelaFormularioMaterial"
              :loading="isLoading"
            />
          </b-col>
          <b-col class="text-right">
            <Button
              text="Confirmar"
              @click="confirmaFormularioMaterial"
              :loading="isLoading"
            />
          </b-col>
        </b-row>
      </template>
    </Modal>

    <Modal
      ref="modalReport"
      no-close-on-esc
      no-close-on-backdrop
      :size="modalReportSize"
      centered
      hide-footer
      @hidden="handleHideModal"
    >
      <DynamicReport
        :reportProcesso="lastRelatorioProceso.reportProcesso"
        :reportsMateriais="lastRelatorioProceso.reportsMateriais"
        :reportType='lastRelatorioProceso.reportType'
        @tipoVisualizacao="(input) => tipoVisualizacao = input"
      />
    </Modal>

    <Modal
      ref="modalQuantidadeGenerico"
      title="Quantidade"
      no-close-on-esc
      no-close-on-backdrop
      size="md"
      centered
      @hidden="cancelaQuantidadeGenerico"
    >
      <form @keyup.enter="confirmaQuantidadeGenerico()" @submit.stop.prevent data-vv-scope="quantidadeGenerico">
        <b-form-input
          name="quantidadeGenerico"
          type="number"
          v-model="quantidadeGenerico"
          autofocus
          class="invision-input input-icon"
          :state="Boolean(quantidadeGenerico)"
        ></b-form-input>
      </form>

      <template #footer>
        <b-row align-h="between" class="pt-4">
          <b-col>
            <Button
              text="Cancelar"
              variant="grayscale"
              type="text"
              @click="cancelaQuantidadeGenerico"
            />
          </b-col>
          <b-col class="text-right">
            <Button
              text="Confirmar"
              @click="confirmaQuantidadeGenerico"
            />
          </b-col>
        </b-row>
      </template>
    </Modal>

    <Modal
      ref="modalNovoProtocolo"
      title="Protocolo"
      subTitle="Faça a leitura do código de barra ou digite o número do protocolo"
      no-close-on-esc
      no-close-on-backdrop
      size="lg"
      centered
      @hidden="cancelaNovoProtocolo"
    >
      <form @keyup.enter="confirmaNovoProtocolo()" @submit.stop.prevent data-vv-scope="novoProtocolo">
        <label v-if="mensagemProtocolo">{{ mensagemProtocolo }}</label>
        <InputProtocolo
          v-model="novosProtocolos"
          @fastConfirm="confirmaNovoProtocolo()"
          multi
        />
      </form>
    </Modal>

    <Modal
      ref="modalSelecaoProcesso"
      title="Processo"
      subTitle="Selecione o processo que será realizado"
      no-close-on-esc
      no-close-on-backdrop
      size="lg"
      centered
      @hidden="cancelaSelecaoProcesso"
    >
      <form @keyup.enter="confirmaSelecaoProcesso()" @submit.stop.prevent data-vv-scope="selecaoProtocolo">
        <b-form-select
          @input="confirmaSelecaoProcesso()"
          v-model="tipoProcessoSelecionado"
          :options="processosDisponiveis.map((p) => ({
            value: p,
            text: p.nome,
          }))"
        />
      </form>
    </Modal>

    <Modal
      ref="modalLoginUsuario"
      title="Validação necessária"
      no-close-on-esc
      no-close-on-backdrop
      hideXClose
      hideFooter
      size="lg"
      centered
    >
      <UserAuthentication
        ref="userAuthentication"
        @authenticationResult="onAuthenticationResult"
        @isBusy="(v) => isBusy.authUser = v"
      />
    </Modal>

    <LoteDetergenteEnzimatico
      v-if="subSetor && subSetor.chave === 'expurgo' && (materiaisLidos.length || protocolos.length)"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';

import GenericApi from '@/services/genericRequest';
import loginService from '@/services/login';

import ExpandableCard from '@/templates/ExpandableCard';
import TransitionExpand from '@/templates/TransitionExpand';

import UserAuthentication from './userAuthentication/UserAuthentication';
import InputProtocolo from './components/InputProtocolo';
import TableMaterial from './components/TableMaterial';
import MaterialDetection from './components/MaterialDetection';
import DynamicForm from './form/DynamicForm';
import DynamicReport from './report/DynamicReport';

import LoteDetergenteEnzimatico from './form/LoteDetergenteEnzimatico';

import Button from '@/components/Utils/Button';
import Modal from '@/components/Utils/Modal';

export default {
  name: 'Processamento',
  components: {
    ExpandableCard,
    TransitionExpand,

    UserAuthentication,
    InputProtocolo,
    TableMaterial,
    MaterialDetection,
    DynamicForm,
    Button,
    Modal,
    DynamicReport,
    LoteDetergenteEnzimatico,
  },
  provide() {
    return {
      validator: this.$validator,
      validateState: this.validateState,
    };
  },

  data() {
    return {
      reportSizes: {
        etiqueta: 'md',
        relatorio: 'xl',
        default: 'lg',
      },
      isBusy: {
        authUser: false,
        protocolo: false,
        materialReading: false,
        sendingForm: false,
      },

      usuarioLogado: {},

      tipoProcessoSelecionado: {},
      processosDisponiveis: [],
      promiseSelecaoProcesso: null,

      materiaisLidos: [],
      protocolos: [],
      processoFormulario: {},
      respostasProcessoFormulario: {},

      respostasMaterialFormulario: {},
      materialSelecionado: {},
      chaveFormularioMaterialSelecionado: null,
      promiseFormularioMaterialObrigatorio: null,

      promiseQuantidadeGenerico: null,
      quantidadeGenerico: null,

      promiseNovoProtocolo: null,
      novosProtocolos: [],
      mensagemProtocolo: '',

      lastRelatorioProceso: {},
      tipoVisualizacao: null,

      countPhotos: 0,
      hideCamera: null,
      hideMateriaisGenerico: null,
    };
  },

  watch: {
    subSetor() {
      this.reset();
    },

    usuarioLogado(usuario) {
      this.$store.commit('setusuarioLogadoProcesso', usuario);
      if (usuario.id_usuario) {
        this.$refs.modalLoginUsuario.hide();
      } else {
        this.$refs.modalLoginUsuario.show();
      }
    },
  },

  computed: {
    ...mapState([
      'currentUnidade',
      'configuracao',
      'subSetores',
      'usuarioLogadoProcesso',
    ]),

    modalReportSize() {
      if (this.lastRelatorioProceso
        && this.lastRelatorioProceso.reportType
        && this.lastRelatorioProceso.reportType.type
        && this.tipoVisualizacao) {
        return this.reportSizes[this.tipoVisualizacao] || 'lg';
      }

      return this.lastRelatorioProceso
        && this.lastRelatorioProceso.reportType
        && this.lastRelatorioProceso.reportType.type
        ? this.reportSizes[this.lastRelatorioProceso.reportType.type] : 'lg';
    },

    isLoading() {
      return Boolean(Object.keys(this.isBusy).some((k) => this.isBusy[k]));
    },

    subSetor() {
      if (this.subSetores.length && this.$route.params.chave) {
        return this.subSetores.find((subSet) => subSet.chave === this.$route.params.chave);
      }

      return null;
    },

    subSetoresFiltered() {
      return this.subSetores.filter((subSetor) => subSetor.chave !== 'arsenalCentroCirurgico');
    },

    formularioMaterialSelecionado() {
      if (!this.materialSelecionado || !this.materialSelecionado.formularios
        || !this.chaveFormularioMaterialSelecionado) return {};

      return this.materialSelecionado.formularios.find((f) => f.title === this.chaveFormularioMaterialSelecionado);
    },

    promiseModalAberta() {
      return this.promiseFormularioMaterialObrigatorio
        || this.promiseQuantidadeGenerico
        || this.promiseNovoProtocolo
        || this.promiseSelecaoProcesso;
    },
  },

  mounted() {
    this.handleLoginUsuario(true);
    this.hideCamera = this.configuracao.leituraMaterialComCamera !== 'true';
    this.hideMateriaisGenerico = this.configuracao.hideMateriaisGenerico === 'true';
  },

  methods: {
    handleHideModal() {
      this.$bvModal.hide('modalReport');
    },

    reset() {
      this.tipoProcessoSelecionado = {};
      this.materiaisLidos = [];
      this.protocolos = [];
      this.processoFormulario = {};
      this.respostasProcessoFormulario = {};
    },

    handleLoginUsuario(justMounted = false) {
      if (!this.$refs.modalLoginUsuario) {
        setTimeout(this.handleLoginUsuario.bind(this), 200, justMounted);
        return;
      }

      if (this.$route.query && this.$route.query.justLoggedIn) {
        this.usuarioLogado = loginService.getUser();
      } else if (this.usuarioLogadoProcesso.id_usuario && !justMounted) {
        this.usuarioLogado = this.usuarioLogadoProcesso;
      }

      if (this.usuarioLogado.id_usuario) {
        this.$refs.modalLoginUsuario.hide();
      } else {
        this.$refs.modalLoginUsuario.show();
      }
    },

    async cancel() {
      const cancelConfirmation = await swal({
        title: 'Cancelamento de formulário',
        text: 'Deseja cancelar o preenchimento do formulário?',
        icon: 'warning',
        buttons: { cancel: 'Não', confirm: 'Sim' },
      });

      if (!cancelConfirmation) return;

      this.reset();
    },

    changeSubsetor(subsetor) {
      this.$router.push(`/Processamento/${subsetor.chave || this.subSetor.chave}`);
    },

    onAuthenticationResult(user) {
      this.usuarioLogado = user;
    },

    async adicionaNovoProtocolo(protocolo) {
      try {
        this.isBusy.protocolo = true;

        const protocoloFromApi = await GenericApi.getWithoutPagination(
          {
            filters: {
              tipoProcesso: this.tipoProcessoSelecionado.chave,
              subSetor: this.subSetor.chave,
              idUnidade: this.currentUnidade && this.currentUnidade.id_unidade,
              protocolos: JSON.stringify([protocolo]),
            },
          },
          'protocolo/adicaoProtocoloProcesso',
        );

        this.protocolos.push(...protocoloFromApi);
        this.isBusy.protocolo = false;
      } catch (error) {
        this.isBusy.protocolo = false;

        let errorMessage = 'Ocorreu um erro ao carregar o protocolo';
        if (error.response && error.response.data && error.response.data.error
          && error.response.data.error.errorMessage) {
          errorMessage = error.response.data.error.errorMessage;
        }

        throw errorMessage;
      }
    },

    onUpdatedProcessoFormAnswer(answer) {
      this.respostasProcessoFormulario = { ...this.respostasProcessoFormulario, ...answer };
    },

    onUpdatedMaterialFormAnswer(answer) {
      this.respostasMaterialFormulario = { ...this.respostasMaterialFormulario, ...answer };
    },

    cancelaFormularioMaterial() {
      this.$refs.modalFormularioMaterial.hide();
      if (this.promiseFormularioMaterialObrigatorio) this.promiseFormularioMaterialObrigatorio(false);
    },

    async validateFormularioMaterial() {
      if (this.currentPage === 0) {
        return this.authenticateUser();
      }

      this.$validator.resume();
      const result = await this.$validator.validateAll('formulario-material');

      return result;
    },

    async confirmaFormularioMaterial() {
      if (!(await this.validateFormularioMaterial())) return;
      this.materialSelecionado
        .respostasFormularios[this.chaveFormularioMaterialSelecionado] = this.respostasMaterialFormulario;

      // Atualizar Qtd itens lidos
      if (this.materialSelecionado
        && this.materialSelecionado.respostasFormularios
        && this.materialSelecionado.respostasFormularios.Composição
        && this.materialSelecionado.respostasFormularios.Composição.itensLidos) {
        this.materialSelecionado.quantidade_atual = 0;
        this.materialSelecionado.respostasFormularios.Composição.itensLidos.forEach((item) => {
          this.materialSelecionado.quantidade_atual = (+this.materialSelecionado.quantidade_atual) + (+item.quantidade);
        });
      } else if (this.materialSelecionado
        && this.materialSelecionado.respostasFormularios
        && this.materialSelecionado.respostasFormularios['Informações do Preparo']
        && this.materialSelecionado.respostasFormularios['Informações do Preparo'].itensLidos) {
        this.materialSelecionado.quantidade_atual = 0;
        this.materialSelecionado.respostasFormularios['Informações do Preparo'].itensLidos.forEach((item) => {
          this.materialSelecionado.quantidade_atual = (+this.materialSelecionado.quantidade_atual) + (+item.quantidade);
        });
      }

      // Atualizar count de fotos do processo
      this.countPhotos = 0;
      this.materiaisLidos.forEach((material) => {
        if (material.respostasFormularios
        && material.respostasFormularios['Informações do Preparo']
        && material.respostasFormularios['Informações do Preparo'].fotos) {
          this.countPhotos += material.respostasFormularios['Informações do Preparo'].fotos.length;
        } else if (material.respostasFormularios
        && material.respostasFormularios.Integridade
        && material.respostasFormularios.Integridade.fotos) {
          this.countPhotos += material.respostasFormularios.Integridade.fotos.length;
        }
      });
      if (this.promiseFormularioMaterialObrigatorio) this.promiseFormularioMaterialObrigatorio(true);
      this.$refs.modalFormularioMaterial.hide();

      // Abre o modal do seguinte formulario
      for (let i = 0; i < this.materialSelecionado.formularios.length; i++) {
        if (this.materialSelecionado.formularios[i].nextOpenModal
        && this.materialSelecionado.formularios[i].immediate) {
          this.materialSelecionado.formularios[i].nextOpenModal = false;
          this.abreEdicaoFormularioMaterial(this.materialSelecionado, this.materialSelecionado.formularios[i].title);
          break;
        }
      }

      if (this.materialSelecionado.quantidade_atual <= 0) {
        this.$refs.modalFormularioMaterial.show();
      }
    },

    abreEdicaoFormularioMaterial(material, formulario) {
      // Switching quantidade de peças entre componentes Procedimento e Integridade
      // para tipo de material medico vindo de setor gera_procedimento
      if (material.formularios[0].fields[0].options.switchQtdPecasProcedimentoIntegridade
        && this.respostasProcessoFormulario.id_setor_origem) {
        const setorGeraProcedimento = Boolean(
          this.respostasProcessoFormulario.id_setor_origem
          === material.formularios[0].fields[0].options.idSetorGeraProcedimento,
        );
        material.formularios[0].fields[0].options.hideQtdPecas = !setorGeraProcedimento;
        material.formularios[1].fields[0].options.hideQtdPecas = setorGeraProcedimento;
      }

      this.chaveFormularioMaterialSelecionado = formulario;
      this.materialSelecionado = this.materiaisLidos
        .find((m) => (m.id_material && m.id_material === material.id_material)
          || (m.id_material_generico && m.id_material_generico === material.id_material_generico));

      this.materialSelecionado.respostasFormularios = this.materialSelecionado.respostasFormularios || {};
      this.respostasMaterialFormulario = this.materialSelecionado
        .respostasFormularios[this.chaveFormularioMaterialSelecionado] || {};

      this.$refs.modalFormularioMaterial.show();
    },

    solicitaFormularioImediato(novoMaterial) {
      const formularioImediato = novoMaterial.formularios.find((f) => f.immediate);

      if (formularioImediato) {
        this.abreEdicaoFormularioMaterial(novoMaterial, formularioImediato.title);
        const promise = new Promise((resolve) => {
          this.promiseFormularioMaterialObrigatorio = resolve;
        });

        promise.then(() => { this.promiseFormularioMaterialObrigatorio = null; });

        return promise;
      }

      return Promise.resolve(true);
    },

    confirmaQuantidadeGenerico() {
      if (!this.quantidadeGenerico || !parseInt(this.quantidadeGenerico, 10)) return;

      if (this.promiseQuantidadeGenerico) this.promiseQuantidadeGenerico(+this.quantidadeGenerico);
      this.$refs.modalQuantidadeGenerico.hide();
    },

    cancelaQuantidadeGenerico() {
      this.$refs.modalQuantidadeGenerico.hide();
      if (this.promiseQuantidadeGenerico) this.promiseQuantidadeGenerico(false);
    },

    async abreModalQuantidade() {
      this.quantidadeGenerico = null;
      this.$refs.modalQuantidadeGenerico.show();
    },

    solicitaQuantidadeMaterialGenerico() {
      this.abreModalQuantidade();
      const promise = new Promise((resolve) => {
        this.promiseQuantidadeGenerico = resolve;
      });

      promise.then(() => { this.promiseQuantidadeGenerico = null; });
      return promise;
    },

    confirmaSelecaoProcesso() {
      if (this.promiseSelecaoProcesso) this.promiseSelecaoProcesso(this.tipoProcessoSelecionado);
      this.$refs.modalSelecaoProcesso.hide();
    },

    cancelaSelecaoProcesso() {
      this.$refs.modalSelecaoProcesso.hide();
      if (this.promiseSelecaoProcesso) this.promiseSelecaoProcesso(false);
    },

    async abreModalSelecaoProcesso() {
      this.novosProtocolos = [];
      this.$refs.modalSelecaoProcesso.show();
    },

    solicitaSelecaoProcesso() {
      this.abreModalSelecaoProcesso();
      const promise = new Promise((resolve) => {
        this.promiseSelecaoProcesso = resolve;
      });

      promise.then(() => { this.promiseSelecaoProcesso = null; });
      return promise;
    },

    async handleTipoProcesso({ value, type }) {
      try {
        this.respostasProcessoFormulario = {};

        const route = `formularios/tiposProcesso/${type}/${value}`;
        const filters = {
          subSetor: this.subSetor.chave,
          idUnidade: this.currentUnidade && this.currentUnidade.id_unidade,
        };
        const tiposProcessoDisponiveis = await GenericApi.getWithoutPagination({ filters }, route);

        if (tiposProcessoDisponiveis.length === 1) {
          [this.tipoProcessoSelecionado] = tiposProcessoDisponiveis;
          return;
        }

        this.processosDisponiveis = tiposProcessoDisponiveis;
        await this.solicitaSelecaoProcesso();
      } catch (error) {
        let errorMessage = 'Ocorreu um erro ao recuperar os processos disponíveis';
        if (error.response && error.response.data && error.response.data.error
          && error.response.data.error.errorMessage) {
          errorMessage = error.response.data.error.errorMessage;
        }

        throw errorMessage;
      }
    },

    confirmaNovoProtocolo() {
      this.$refs.modalNovoProtocolo.hide();

      this.novosProtocolos = this.novosProtocolos.filter((np) => !this.protocolos.map((p) => p.codigo).includes(np));
      if (this.promiseNovoProtocolo) this.promiseNovoProtocolo(this.novosProtocolos);
    },

    cancelaNovoProtocolo() {
      this.$refs.modalNovoProtocolo.hide();
      if (this.promiseNovoProtocolo) this.promiseNovoProtocolo(false);
    },

    async abreModalNovoProtocolo() {
      this.novosProtocolos = [];
      this.$refs.modalNovoProtocolo.show();
    },

    solicitaNovoProtocolo() {
      this.abreModalNovoProtocolo();
      const promise = new Promise((resolve) => {
        this.promiseNovoProtocolo = resolve;
      });

      promise.then(() => { this.promiseNovoProtocolo = null; });
      return promise;
    },

    async handleProtocoloGenerico({ value, type }) {
      try {
        const route = `formularios/necessidadeProtocolo/${type}/${value}`;
        const filters = {
          subSetor: this.subSetor.chave,
          tipoProcesso: this.tipoProcessoSelecionado.chave,
        };
        const necessidadeProtocolo = await GenericApi.getWithoutPagination({ filters }, route);

        if (!necessidadeProtocolo.usaProtoclo) return false;

        const novosProtocolos = await this.solicitaNovoProtocolo();
        if (novosProtocolos && novosProtocolos.length) return novosProtocolos;

        if (!novosProtocolos.length && !necessidadeProtocolo.obrigatorio) {
          // Se o material lido pode ser usado com protocolo e criar protocolo
          // e não for inserido número de protocolo apresentará a mensagem com
          // as opçóes para continuar e criar novo protocolo ou inserir o número
          // de protocolo a usar.
          const gerarProtocoloGenerico = await swal({
            title: 'Atenção!',
            // eslint-disable-next-line max-len
            text: '- O número de protocolo não foi inserido, o que resultará na geração de um novo protocolo.\n\n- Caso este material tenha vindo de outra unidade, por favor, insira o número de protocolo ou entre em contato com a supervisão para assistência.',
            icon: 'warning',
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: {
              cancel: {
                text: 'Inserir Protocolo',
                visible: true,
                className: 'btn-secundary-group',
              },
              confirm: {
                text: 'Continuar',
              },
            },
          });
          if (gerarProtocoloGenerico) return [];
          return this.handleProtocoloGenerico({ value, type });
        }

        if (!necessidadeProtocolo.obrigatorio) return [];
        if (this.protocolos.length) return [];

        throw 'Leitura cancelada pois não foi inserido protocolo';
      } catch (error) {
        if (typeof error === 'string') throw error;

        let errorMessage = 'Ocorreu um erro ao validar a necessidade de protoclo genérico';
        if (error.response && error.response.data && error.response.data.error
          && error.response.data.error.errorMessage) {
          errorMessage = error.response.data.error.errorMessage;
        }

        throw errorMessage;
      }
    },

    removeMaterial(material) {
      let indexMaterial;
      do {
        indexMaterial = this.materiaisLidos
          .findIndex((m) => (m.id_material && m.id_material === material.id_material)
            || (m.id_material_generico && m.id_material_generico === material.id_material_generico));
        if (indexMaterial !== -1) {
          // Se tem formulário de integridade ou de informações do preparo, verifica se tem fotos e retira da contagem
          if (this.materiaisLidos[indexMaterial].respostasFormularios
          && this.materiaisLidos[indexMaterial].respostasFormularios.Integridade
          && this.materiaisLidos[indexMaterial].respostasFormularios.Integridade.fotos
          && this.materiaisLidos[indexMaterial].respostasFormularios.Integridade.fotos.length) {
            this.countPhotos -= this.materiaisLidos[indexMaterial].respostasFormularios.Integridade.fotos.length;
          } else if (this.materiaisLidos[indexMaterial].respostasFormularios['Infomações do Preparo']
          && this.materiaisLidos[indexMaterial].respostasFormularios['Informações do Preparo']) {
            this.countPhotos -= this.materiaisLidos[indexMaterial].respostasFormularios['Infomações do Preparo']
              .fotos.length;
          }
          this.materiaisLidos.splice(indexMaterial, 1);
        }
      } while (indexMaterial !== -1);
    },

    removeMaterialProtocolo(material) {
      if (material.id_material) {
        const indexMaterial = this.materiaisLidos
          .findIndex((m) => (m.id_material && m.id_material_protocolo === material.id_material_protocolo));

        if (indexMaterial !== -1) this.materiaisLidos.splice(indexMaterial, 1);
      } else {
        const indexMaterial = this.materiaisLidos
          .findIndex((m) => (m.id_material_generico && m.id_material_generico === material.id_material_generico));

        if (indexMaterial !== -1) this.materiaisLidos.splice(indexMaterial, 1);
      }
    },

    adicionaMaterial(resultadoLeitura, quantidadeAdicionada) {
      this.processoFormulario = resultadoLeitura.formularioProcesso;

      const novoMaterial = {
        id_material: resultadoLeitura.materialLido.id_material,
        id_material_generico: resultadoLeitura.materialLido.id_material_generico,
        id_material_protocolo: resultadoLeitura.materialLido.id_material_protocolo,
        ids_material_protocolo: resultadoLeitura.materialLido.ids_material_protocolo,
        shownId: resultadoLeitura.materialLido.shownId || '-',
        quantidade_adicionada: quantidadeAdicionada || 1,
        quantidade_atual: resultadoLeitura.materialLido.quantidade_atual_itens || quantidadeAdicionada,
        quantidade_pecas: resultadoLeitura.materialLido.quantidade_itens || resultadoLeitura.materialLido.pecas,
        descricao: resultadoLeitura.materialLido.descricao,
        formularios: resultadoLeitura.formularioMaterial,
        processoTipoMaterial: resultadoLeitura.processoTipoMaterial,
        respostasFormularios: {},
      };

      this.materiaisLidos.push(novoMaterial);
      return novoMaterial;
    },

    async onMaterialSelection({ value, type, quantidade }, solicitaProtocolo = false) {
      let novoMaterial;
      let quantidadeAdicionada = quantidade;
      if (this.promiseModalAberta) return;

      if (!this.usuarioLogado.id_usuario) {
        this.handleLoginUsuario();
        return;
      }

      try {
        this.isBusy.materialReading = true;

        if (!this.tipoProcessoSelecionado.chave) {
          await this.handleTipoProcesso({ value, type });
          if (!this.tipoProcessoSelecionado.chave) {
            swal({
              icon: 'error',
              title: 'Erro',
              text: 'Leitura cancelada',
              buttons: { confirm: 'Fechar' },
            });

            return;
          }
        }

        if (type === 'code' && value.slice(0, 6) === 'INVMGE') {
          quantidadeAdicionada = await this.solicitaQuantidadeMaterialGenerico();
          if (!quantidadeAdicionada) {
            swal({
              icon: 'error',
              title: 'Erro',
              text: 'Leitura do material cancelada',
              buttons: { confirm: 'Fechar' },
            });

            return;
          }
        }

        let novosProtocolos;
        const protocolos = this.protocolos.map((p) => p.codigo);
        if (!solicitaProtocolo && ((type === 'code' && value.slice(0, 6) === 'INVMGE') || type === 'generico')) {
          this.mensagemProtocolo = '';

          novosProtocolos = await this.handleProtocoloGenerico({ value, type });
          if (novosProtocolos) {
            // eslint-disable-next-line no-restricted-syntax
            for (const p of novosProtocolos) {
              protocolos.push(p);
              // eslint-disable-next-line no-await-in-loop
              await this.adicionaNovoProtocolo(p);
            }
          }
        }

        if (solicitaProtocolo) {
          novosProtocolos = await this.solicitaNovoProtocolo();

          // * Existiam situações em que o código não aguardava a resolução da promise
          // * Não consegui entender o motivo para arrumar
          // * O código abaixo, espera de 100ms, faz o problema não ocorrer
          await new Promise((r) => { setTimeout(r, 100); });

          if (!novosProtocolos) throw 'Leitura cancelada pois não foi inserido protocolo';

          // eslint-disable-next-line no-restricted-syntax
          for (const p of novosProtocolos) {
            protocolos.push(p);
            // eslint-disable-next-line no-await-in-loop
            await this.adicionaNovoProtocolo(p);
          }
        }

        const route = `formularios/leitura/${type}/${value}`;
        const data = {
          tipoProcesso: this.tipoProcessoSelecionado.chave,
          subSetor: this.subSetor.chave,
          idUnidade: this.currentUnidade && this.currentUnidade.id_unidade,
          quantidade: quantidadeAdicionada,
          protocolos: JSON.stringify(protocolos),
          materiaisLidos: JSON.stringify(this.materiaisLidos),
          processoFormularioAtual: JSON.stringify(this.processoFormulario),
        };
        const resultadoLeitura = await GenericApi.create(data, route);

        const materialJaExiste = this.materiaisLidos
          .find((m) => (m.id_material && m.id_material === resultadoLeitura.materialLido.id_material)
          || (m.id_material_generico && m.id_material_generico === resultadoLeitura.materialLido.id_material_generico));

        if (materialJaExiste) {
          swal({
            icon: 'error',
            title: 'Erro',
            text: 'O material informado já foi lido',
            buttons: { confirm: 'Fechar' },
          });

          return;
        }

        if (resultadoLeitura.precisaNovoProtocolo) {
          if (resultadoLeitura.novoProtocolo) {
            const protocolo = `${resultadoLeitura.novoProtocolo.sigla}${resultadoLeitura.novoProtocolo.numero}`;
            await this.adicionaNovoProtocolo(protocolo);
          } else {
            if (((type === 'code' && value.slice(0, 6) === 'INVMGE') || type === 'generico')) {
              this.mensagemProtocolo = 'Os protocolos adicionados não permitem a passagem de todos os materiais';
            } else {
              this.mensagemProtocolo = `Não foi possível obter o protocolo automaticamente ou o ${''
              }material pode ser substituído, favor informar manualmente`;
            }

            this.onMaterialSelection({ value, type, quantidade }, true);
            return;
          }
        }

        // Atualiza os protocolos, isso é necessário devido a substituição de materiais
        if (resultadoLeitura.materiaisLidos.length) {
          resultadoLeitura.materiaisLidos.forEach((material) => {
            const materialExistente = this.materiaisLidos
              .find((m) => (m.id_material && m.id_material === material.id_material)
                || (m.id_material_generico && m.id_material_generico === material.id_material_generico));

            if (materialExistente) materialExistente.id_material_protocolo = material.id_material_protocolo;
          });
        }

        novoMaterial = this.adicionaMaterial(resultadoLeitura, quantidadeAdicionada);
      } catch (error) {
        let errorMessage = 'Ocorreu um erro ao recuperar o material';
        if (error.response && error.response.data && error.response.data.error
          && error.response.data.error.errorMessage) {
          errorMessage = error.response.data.error.errorMessage;
        }

        if (typeof error === 'string') errorMessage = error;

        swal({
          icon: 'error',
          title: 'Erro',
          text: errorMessage,
          buttons: { confirm: 'Fechar' },
        });
      } finally {
        this.isBusy.materialReading = false;

        if (novoMaterial && !(await this.solicitaFormularioImediato(novoMaterial))) {
          this.materiaisLidos.pop();

          swal({
            icon: 'error',
            title: 'Erro',
            text: 'Leitura do material cancelada',
            buttons: { confirm: 'Fechar' },
          });
        }
      }
    },

    async save() {
      this.$validator.resume();
      const validationResult = await this.$validator.validateAll('main-form');
      if (!validationResult) return;

      try {
        this.isBusy.sendingForm = true;

        const protocolos = JSON.stringify(this.protocolos.map((p) => p.codigo));
        const materiais = JSON.stringify(this.materiaisLidos);
        const formularioProcesso = JSON.stringify(this.respostasProcessoFormulario);
        const tipoProcesso = this.tipoProcessoSelecionado.chave;
        const subSetor = this.subSetor.chave;
        const idUnidade = this.currentUnidade && this.currentUnidade.id_unidade;
        const idUsuario = this.usuarioLogado.id_usuario;

        const resultado = await GenericApi.create({
          protocolos,
          materiais,
          formularioProcesso,
          tipoProcesso,
          subSetor,
          idUnidade,
          idUsuario,
        }, 'formularios/registroProcesso');

        this.lastRelatorioProceso = resultado;
        this.$refs.modalReport.show();

        this.tipoProcessoSelecionado = {};
        this.protocolos = [];

        this.reset();
      } catch (error) {
        let errorMessage = 'Ocorreu um erro ao registrar o processo';
        if (error.response && error.response.data && error.response.data.error
          && error.response.data.error.errorMessage) {
          errorMessage = error.response.data.error.errorMessage;
        }

        swal({
          icon: 'error',
          title: 'Erro',
          text: errorMessage,
          buttons: { confirm: 'Fechar' },
        });
      } finally {
        this.isBusy.sendingForm = false;
      }
    },

    validateState(ref) {
      const allVeeFields = Object.keys(this.veeFields)
        .reduce((fields, scope) => ({ ...fields, ...this.veeFields[scope] }), {});

      if (
        allVeeFields[ref]
        && document.getElementsByName(ref)[0]
        && !document.getElementsByName(ref)[0].disabled
        && (allVeeFields[ref].dirty || allVeeFields[ref].validated)
      ) {
        return !this.veeErrors.has(`main-form.${ref}`) && !this.veeErrors.has(`formulario-material.${ref}`);
      }
      return null;
    },
  },
};
</script>

<style lang='scss' scoped>
$neutral-color-80: #35384d;
  // HEADER
  .header {
    margin-top: -36px;
    padding-top: 2rem;
    padding-left: 4rem;
    padding-right: 4rem;
    background-color: #FFFFFF;
    border-bottom: 1px solid #CFD1E6;
  }

  .subsetor-label {
    font-weight: 500;
    font-size: 2rem;
    padding-bottom: 1rem;
    color: #35384D !important;
  }

  // EMPTY
  .empty-div {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    transition: opacity 0.4s;
  }
  .empty-div.hidden {
    opacity: 0;
  }

  .empty-label {
    color: #5E627A;
  }

  // MAIN
  .main {
    padding-top: 3rem;
    padding-left: 4rem;
    padding-right: 4rem;
  }

  // FORM
  .empty-form {
    font-size: 1rem;
    color: #7E829F !important;
    margin-top: 15px;
  }
</style>

<style>
.subsetor-dropdown .button {
  background-color: white !important;
  margin-left: 10px;
  border: 2px solid #e3e1ed !important;
  border-radius: 7px !important;
  color: black;
  height: 2rem;
  width: 2rem;
}

.subsetor-dropdown .chevron {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
