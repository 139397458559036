<template>
  <div>
    <InputTags
      label="Números dos protocolos"
      name="protocolo"
      v-model="protocolos"
      @input="onInput"
      placeholder="Inserir protocolo..."
      :state="validateState('protocolo')"
      v-validate="{ required }"
      autofocus
    >
      <template #description>
        <div>
          Insira os números de protocolos que deseja registrar no processo.
        </div>
      </template>
    </InputTags>
  </div>
</template>

<script>
import InputTags from '@/components/Form/InputTags';

export default {
  name: 'InputProtocolo',
  inject: ['validator', 'validateState'],
  components: {
    InputTags,
  },

  props: {
    isBusy: {
      type: Boolean,
      required: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    multi: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      protocolos: [],
    };
  },

  created() {
    this.$validator = this.validator;
  },

  methods: {
    async onInput(protocolos) {
      const protocolosUpperCase = protocolos.map((p) => p.toUpperCase());
      this.$emit('input', protocolosUpperCase);

      if (!this.multi) this.$emit('fastConfirm');
    },
  },
};
</script>
