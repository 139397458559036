<template>
  <b-col sm="12" md="12" lg="12" xl="12">
    <b-form-group label="Tipo de teste*" label-for="tipo_teste">
      <GenericSelect
        name="tipo_teste"
        labelKey="nome"
        route="tipoTeste"
        :disabled="isBusy"
        disabledFirstOption
        v-validate="{ required: true }"
        :state="validateState('tipo_teste')"
        v-model="id_tipo_teste"
        :customFilters="options.filters"
        @input="(v) => $emit('updateModel', { id_tipo_teste: v })"
      ></GenericSelect>
    </b-form-group>
  </b-col>
</template>

<script>
import GenericSelect from '@/components/Form/GenericSelect';

export default {
  name: 'TesteEsterilizacao',
  inject: ['validator', 'validateState'],
  created() {
    this.$validator = this.validator;
  },

  components: {
    GenericSelect,
  },

  data() {
    return {
      id_tipo_teste: this.options.testePadrao || null,
    };
  },

  mounted() {
    this.$emit('updateModel', { id_tipo_teste: this.options.testePadrao || null });
  },

  props: {
    isBusy: {
      type: Boolean,
    },
    options: {
      type: Object,
      required: false,
    },
  },
};
</script>
