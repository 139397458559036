<template>
  <b-col sm="12" md="12" lg="12" xl="12">
    <b-form-group
      label="Sala*"
      label-for="sala"
      label-class="float-left"
    >
      <b-form-input
        id="sala"
        name="sala"
        v-model="sala"
        class="invision-input input-icon"
        :disabled="isBusy"
        v-validate="'required'"
        :state="validateState('sala')"
        @input="(v) => $emit('updateModel', { sala: v })"
      />
    </b-form-group>
  </b-col>
</template>

<script>
export default {
  name: 'Sala',
  inject: [
    'validator',
    'validateState',
  ],
  created() {
    this.$validator = this.validator;
  },
  data() {
    return {
      sala: '',
    };
  },
  props: {
    isBusy: {
      type: Boolean,
    },
    options: {
      type: Object,
      required: false,
    },
  },
};
</script>
