<template>
<b-col sm="12" md="12" lg="12" xl="12">
  <b-form-group
    label="Paciente*"
    label-for="paciente"
    label-class="float-left"
  >
    <b-form-input
      id="paciente"
      name="paciente"
      v-model="paciente"
      class="invision-input input-icon"
      :disabled="isBusy"
      type="text"
      v-validate="options.validate"
      :state="validateState('paciente')"
      @input="(v) => $emit('updateModel', { paciente: v })"
    />
  </b-form-group>
</b-col>
</template>

<script>
export default {
name: 'Paciente',
inject: [
  'validator',
  'validateState',
],
created() {
  this.$validator = this.validator;
},
data() {
  return {
    paciente: '',
  };
},
props: {
  isBusy: {
    type: Boolean,
  },
  options: {
    type: Object,
    required: false,
  },
},
};
</script>