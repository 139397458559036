<template>
  <b-col sm="12" md="12" lg="12" xl="12">
    <YesOrNoRadio
      name="validade_vencida"
      label="Validade Vencida"
      v-model="validade_vencida"
      @input="(v) => $emit('updateModel', { validade_vencida: v })"
      :disabled="isBusy"
      v-validate="{ required: true }"
      :state="validateState('validade_vencida')"
    />
  </b-col>
</template>

<script>
import YesOrNoRadio from '@/components/Utils/YesOrNoRadio';

export default {
  name: 'ValidadeVencida',
  inject: ['validator', 'validateState'],
  created() {
    this.$validator = this.validator;
  },

  components: { YesOrNoRadio },

  data() {
    return {
      validade_vencida: this.options.notSelectedOption ? null : Boolean(this.options.value),
    };
  },

  mounted() {
    this.$emit('updateModel', { validade_vencida: this.validade_vencida });
  },

  props: {
    isBusy: {
      type: Boolean,
    },
    options: {
      /**
      * Chaves usadas para definir a opção do componente YesOrNoRadio,
      * no caso de não estar presentes serão setados os valores padrão
      * @param {boolean} notSelectedOption permite setar ou não uma opção
      * @param {boolean} value define a opção que estará selecionada
      */
      type: Object,
      required: false,
    },
  },
};
</script>
