<template>
      <b-col v-show="!options.hide" sm="12" md="12" lg="12" xl="12">
        <b-form-group
          label="Lote do Equipamento de Esterilização/Desinfeccao*"
          label-for="lote"
        >
          <b-form-input
            name="lote"
            autocomplete="off"
            class="invision-input"
            :disabled="isBusy"
            v-validate="{ required: options.requiredLote }"
            :state="validateState('lote')"
            v-model="lote"
            @input="(v) => $emit('updateModel', { lote: v })"
          >
          </b-form-input>
        </b-form-group>
      </b-col>
</template>

<script>

export default {
  name: 'LoteEquipamentoEsterilizacao',
  inject: ['validator', 'validateState'],
  created() {
    this.$validator = this.validator;
  },

  data() {
    return {
      lote: null,
    };
  },

  props: {
    isBusy: {
      type: Boolean,
    },
    options: {
      type: Object,
      required: false,
    },
  },
};
</script>
