<template>
  <b-col sm="12" md="12" lg="12" xl="12">
    <b-form-group label="Tipo de embalagem*" label-for="tipo_embalagem">
      <GenericSelect
        name="tipo_embalagem"
        labelKey="nome"
        route="tipoEmbalagem"
        v-validate="{ required: true }"
        disabledFirstOption
        :state="validateState('tipo_embalagem')"
        v-model="id_tipo_embalagem"
        :customFilters="options.filters"
        @input="(v) => $emit('updateModel', { id_tipo_embalagem: v })"
      ></GenericSelect>
    </b-form-group>
  </b-col>
</template>

<script>
import GenericSelect from '@/components/Form/GenericSelect';

export default {
  name: 'TipoEmbalagem',
  inject: ['validator', 'validateState'],
  created() {
    this.$validator = this.validator;
  },

  components: {
    GenericSelect,
  },

  data() {
    return {
      id_tipo_embalagem: this.options.embalagemPadrao || null,
    };
  },

  mounted() {
    this.$emit('updateModel', { id_tipo_embalagem: this.id_tipo_embalagem });
  },

  props: {
    isBusy: {
      type: Boolean,
    },
    options: {
      type: Object,
      required: false,
    },
  },
};
</script>
