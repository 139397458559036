<template>
<div style="width: 100%;">
    <PhotoView
      :isBusy="isBusy"
      :options="options.PhotoView"
      margin="-8% 0 0 94%"
      v-if="options.PhotoView
        && options.PhotoView.fotosItem
        && options.PhotoView.fotosItem.length"
    />

    <QuantidadePecas
      ref="form-QuantidadePecas"
      v-if="options.QuantidadePecas && !options.hideQtdPecas"
      :isBusy="isBusy"
      :options="options.QuantidadePecas"
      @updateModel="onUpdatedModel"
    />

    <Desincrustante
      ref="form-Desincrustante"
      v-if="options.Desincrustante"
      :isBusy="isBusy"
      :options="options.Desincrustante"
      @updateModel="onUpdatedModel"
    />

    <ItensDefeituosos
      ref="form-ItensDefeituosos"
      v-if="options.ItensDefeituosos"
      :isBusy="isBusy"
      :options="options.ItensDefeituosos"
      @updateModel="onUpdatedModel"
    />

    <PerfuroCortante
      ref="form-PerfuroCortante"
      v-if="options.PerfuroCortante"
      :isBusy="isBusy"
      :options="options.PerfuroCortante"
      @updateModel="onUpdatedModel"
    />

    <ValidadeVencida
      ref="form-ValidadeVencida"
      v-if="options.ValidadeVencida"
      :isBusy="isBusy"
      :options="options.ValidadeVencida"
      @updateModel="onUpdatedModel"
    />

    <Observacoes
      v-if="options.ativaObservacoes"
      ref="form-Observacoes"
      :isBusy="isBusy"
      @updateModel="onUpdatedModel"
    />

    <br />
    <b-row>
      <b-col
        v-if="!options.hideButtonAddPhotos"
        sm="12" md="12" lg="12" xl="12">
        <Button
          :text="`Tirar foto ${fotos.length > 0 ? `(${fotos.length})` : ''}`"
          variant="grayscale"
          @click="$refs.modalPhoto.show()"
          :disabled="isBusy"
        />
      </b-col>
    </b-row>
    <Modal
      ref="modalPhoto"
      title="Adicionar fotos do material"
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      size="lg"
      centered
    >
      <PhotoForm
        @newPhoto="updateFoto"
        :countPhotos="countPhotos"
        @confirmar="$refs.modalPhoto.hide()"
        :photos="fotos"
        buttonConfirmar
      />
    </Modal>
</div>
</template>

<script>
import ValidadeVencida from './ValidadeVencida';
import PhotoView from '@/components/Utils/PhotoView';
import QuantidadePecas from './QuantidadePecas';
import Observacoes from './Observacoes';
import ItensDefeituosos from './ItensDefeituosos';
import Desincrustante from './Desincrustante';
import PerfuroCortante from './PerfuroCortante';
import PhotoForm from '@/components/WebCam/PhotoForm';
import Button from '@/components/Utils/Button';
import Modal from '@/components/Utils/Modal';

export default {
  name: 'Integridade',
  inject: ['validator', 'validateState'],
  created() {
    this.$validator = this.validator;
  },

  components: {
    QuantidadePecas,
    Observacoes,
    ItensDefeituosos,
    Desincrustante,
    PerfuroCortante,
    PhotoForm,
    PhotoView,
    Button,
    Modal,
    ValidadeVencida,
  },

  props: {
    isBusy: {
      type: Boolean,
    },
    options: {
      type: Object,
      required: false,
    },
    answers: {
      type: Object,
      required: true,
    },
    countPhotos: {
      type: Number,
      required: false,
    },
  },

  data() {
    return {
      quantidade_pecas: 0,
      materiais_defeituosos: false,
      perfuro_cortante: false,
      desincrustante: true,

      fotos: [],
    };
  },

  mounted() {
    Object.keys(this.$refs).forEach((componentKey) => {
      if (!componentKey.startsWith('form-')) return;
      const component = this.$refs[componentKey];

      Object.keys(this.answers).forEach((key) => {
        // eslint-disable-next-line no-prototype-builtins
        if (!component.hasOwnProperty(key)) return;

        component[key] = this.answers[key];
        this.onUpdatedModel({ [key]: component[key] });
      });
    });
  },

  computed: {
    possuiInconformidade() {
      if (this.options.QuantidadePecas && +this.quantidade_pecas !== +this.options.quantidadeItens) return true;
      if (this.options.ItensDefeituosos && this.materiais_defeituosos) return true;
      if (this.options.PerfuroCortante && this.perfuro_cortante) return true;
      if (this.options.Desincrustante && !this.desincrustante) return true;
      return false;
    },
  },

  watch: {
    // possuiInconformidade(value) {
    //   if (!value) this.updateFoto([]);
    // },
  },

  methods: {
    onUpdatedModel(value) {
      const key = Object.keys(value)[0];

      if (['quantidade_pecas', 'materiais_defeituosos', 'perfuro_cortante', 'desincrustante'].includes(key)) {
        this[key] = value[key];
      }

      this.$emit('updateModel', value);
    },

    updateFoto(image) {
      this.fotos = image;

      this.$emit('updateModel', { fotos: this.fotos });
    },
  },
};
</script>
